import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import '../../Styles/Orders.css';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
    authDomain: "hungrie-5717f.firebaseapp.com",
    projectId: "hungrie-5717f",
    storageBucket: "hungrie-5717f.appspot.com",
    messagingSenderId: "121136828215",
    appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
    measurementId: ""
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);

const UpdateItems = () => {
    const [firestoreDetails, setFirestoreDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [editItem, setEditItem] = useState(null);
    const [updatedTitle, setUpdatedTitle] = useState('');
    const [updatedImage, setUpdatedImage] = useState(null);
    const [updatedPrice, setUpdatedPrice] = useState('');
    const [updatedSizes, setUpdatedSizes] = useState({
        small: '',
        medium: '',
        large: '',
        xlarge: ''
    });

    useEffect(() => {
        fetchFirestoreDetails();
    }, []);

    const fetchFirestoreDetails = async () => {
        try {
            const menuItemsSnapshot = await getDocs(collection(firestore, 'MenuItems'));
            const menuItems = menuItemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            const dealsSnapshot = await getDocs(collection(firestore, 'Deals'));
            const deals = dealsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const details = [...menuItems, ...deals];
            setFirestoreDetails(details);
        } catch (error) {
            console.error('Error fetching Firestore details:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (item) => {
        setEditItem(item);
        setUpdatedTitle(item.title);
        setUpdatedImage(null);
        setUpdatedPrice(item.price);
        
        if (item.category === 'Pizza') {
            const sizePrices = item.sizes.reduce((acc, size) => {
                acc[size.size.toLowerCase()] = size.price;
                return acc;
            }, {});
            setUpdatedSizes(sizePrices);
        }
    };

    const handleImageUpload = async (file) => {
        const imageRef = ref(storage, `images/${file.name}`);
        try {
            const snapshot = await uploadBytes(imageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            setUpdatedImage(downloadURL); // Store image URL after upload
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };

    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
        if (!updatedTitle || !updatedImage || (editItem.category !== 'Pizza' && !updatedPrice)) {
            alert("Please fill in all fields.");
            return;
        }

        try {
            const itemRef = doc(firestore, 'MenuItems', editItem.id);
            const updatedData = {
                title: updatedTitle,
                image: updatedImage,
            };

            if (editItem.category === 'Pizza') {
                updatedData.sizes = [
                    { size: 'small(7)', price: parseFloat(updatedSizes.small) },
                    { size: 'medium(10)', price: parseFloat(updatedSizes.medium) },
                    { size: 'large(13)', price: parseFloat(updatedSizes.large) },
                    { size: 'x-large(16)', price: parseFloat(updatedSizes.xlarge) },
                ];
            } else {
                updatedData.price = parseFloat(updatedPrice);
            }

            await updateDoc(itemRef, updatedData);

            alert("Item updated successfully!");
            setEditItem(null);
            setUpdatedTitle('');
            setUpdatedImage(null);
            setUpdatedPrice('');
            setUpdatedSizes({ small: '', medium: '', large: '', xlarge: '' });
            setLoading(true);
            fetchFirestoreDetails();
        } catch (error) {
            console.error("Error updating item:", error);
        }
    };

    const handleRemoveClick = async (itemId, category) => {
        // Ensure the category and itemId are not undefined or empty
        if (!itemId || !category) {
            alert('Invalid item or category.');
            return;
        }
    
        // Construct the document reference
        const itemRef = doc(firestore, category, itemId);
    
        try {
            await deleteDoc(itemRef);
            alert("Item removed successfully!");
            setLoading(true);
            fetchFirestoreDetails(); // Reload the items after deletion
        } catch (error) {
            console.error("Error removing item:", error);
            alert("Failed to remove item. Please try again.");
        }
    };
    

    return (
        <div className="orders-container">
            <h1 className="orders-title">Update Item</h1>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search items with title or special number (eg: :123)"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>

            {loading ? (
                <p className="loading-text">Loading items...</p>
            ) : (
                <>
                    <ul className="item-list">
                        {firestoreDetails.filter(item => item.title.toLowerCase().includes(searchTerm.toLowerCase())).map(item => (
                            <li key={item.id} className="item-card">
                                <img src={item.image} alt={item.title} className="item-image" />
                                <h2 className="item-title">{item.title}</h2>
                                <p className="item-price">Price: {item.category === 'Pizza' ? 'Varies by size' : `Rs. ${item.price}`}</p>
                                <button className="add-to-cart-button" onClick={() => handleEditClick(item)}>Update</button>
                                <button 
                                   className="add-to-cart-button"
                                   style={{borderTop:'1px solid silver'}}
                                    onClick={() => handleRemoveClick(item.id, item.category)}
                                >
                                    Remove
                                </button>
                            </li>
                        ))}
                    </ul>

                    {editItem && (
                        <div className="update-form">
                            <div className="form-container">
                                <button className="close-btn" onClick={() => setEditItem(null)}>&times;</button>
                                <h2>Edit Item</h2>
                                <form onSubmit={handleUpdateSubmit}>
                                    <div>
                                        <label>Title:</label>
                                        <input
                                            type="text"
                                            value={updatedTitle}
                                            onChange={(e) => setUpdatedTitle(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label>Image:</label>
                                        <input
                                            type="file"
                                            onChange={(e) => handleImageUpload(e.target.files[0])}
                                        />
                                    </div>
                                    {editItem.category === 'Pizza' ? (
                                        <>
                                            <div>
                                                <label>Small Price (7 inches):</label>
                                                <input
                                                    type="number"
                                                    value={updatedSizes.small}
                                                    onChange={(e) => setUpdatedSizes({ ...updatedSizes, small: e.target.value })}
                                                />
                                            </div>
                                            <div>
                                                <label>Medium Price (10 inches):</label>
                                                <input
                                                    type="number"
                                                    value={updatedSizes.medium}
                                                    onChange={(e) => setUpdatedSizes({ ...updatedSizes, medium: e.target.value })}
                                                />
                                            </div>
                                            <div>
                                                <label>Large Price (13 inches):</label>
                                                <input
                                                    type="number"
                                                    value={updatedSizes.large}
                                                    onChange={(e) => setUpdatedSizes({ ...updatedSizes, large: e.target.value })}
                                                />
                                            </div>
                                            <div>
                                                <label>X-Large Price (16 inches):</label>
                                                <input
                                                    type="number"
                                                    value={updatedSizes.xlarge}
                                                    onChange={(e) => setUpdatedSizes({ ...updatedSizes, xlarge: e.target.value })}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div>
                                            <label>Price:</label>
                                            <input
                                                type="number"
                                                value={updatedPrice}
                                                onChange={(e) => setUpdatedPrice(e.target.value)}
                                            />
                                        </div>
                                    )}
                                    <button type="submit">Update Item</button>
                                </form>
                                <button className="cancel-btn" onClick={() => setEditItem(null)}>Cancel</button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default UpdateItems;
