import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, deleteDoc, setDoc } from 'firebase/firestore';
import '../../Styles/Orders.css'; // Import the CSS file

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
    authDomain: "hungrie-5717f.firebaseapp.com",
    projectId: "hungrie-5717f",
    storageBucket: "hungrie-5717f.appspot.com",
    messagingSenderId: "121136828215",
    appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
    measurementId: ""
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const AppOrders = () => {
    const [firestoreDetails, setFirestoreDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [changeText, setchangeText] = useState(true);
    
    useEffect(() => {


        fetchFirestoreDetails();
    }, []);
    const fetchFirestoreDetails = async () => {
        try {
            const querySnapshot = await getDocs(collection(firestore, 'Dinning'));
            const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            // Sort orders by date in descending order
            details.sort((a, b) => new Date(b.date) - new Date(a.date));
            console.log('firestore data', details);
            setFirestoreDetails(details);
        } catch (error) {
            console.error('Error fetching Firestore details:', error);
        } finally {
            setLoading(false);
        }
    };
    const completeOrder = async (order) => {
        setchangeText(false)
        const orderRef = doc(firestore, 'Dinning', order.id);
        const completedOrderRef = doc(collection(firestore, 'CompleteDinning'));

        try {
            // Add the order to CompleteDinning
            await setDoc(completedOrderRef, { ...order, completedAt: new Date().toISOString() });
            // Remove the order from Dinning
            await deleteDoc(orderRef);
            // Update local state
            setFirestoreDetails(prevOrders => prevOrders.filter(o => o.id !== order.id));
            fetchFirestoreDetails()
        } catch (error) {
            console.error('Error completing order:', error);
        }
    };

    return (
        <div className="orders-container">
            <h1 className="title">Dinning</h1>
            {loading ? (
                <p className="loading-text">Loading orders...</p>
            ) : (
                <>
                    <ul className="orders-list">
                        {firestoreDetails.map((order) => (
                            <li key={order.id} className="order-item">
                                <p className="order-details">
                                    Order <span className="order-number">#{order.id}</span> - Total: <span className="total">Rs. {order.totalPrice}</span> - Table: <span className="table-number">{order.tableNumber}</span> - Date: <span className="date">{new Date(order.date).toLocaleString()}</span>
                                </p>
                                <ul className="line-items">
                                    {order.items && order.items.length > 0 ? (
                                        order.items.map((item) => (
                                            <li key={item.id} className="line-item">
                                                {/* <img src={item.image} alt={item.title} className="item-image" /> */}
                                                <span style={{ fontWeight: 'bold' }}>{item.title}</span> - Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span> - Price: <span className="item-price">Rs. {item.price}</span>
                                            </li>
                                        ))
                                    ) : (
                                        <li>No items in this order.</li>
                                    )}
                                </ul>
                                <button onClick={() => completeOrder(order)}  style={{border:'1px solid silver' ,marginTop:'10px',textAlign:'center',backgroundColor:'#c84347',color:'white'} }>
                                    {
                                        changeText?
                                        'Complete Order' :
                                        'Completing...'
                                    }
                                </button>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
};

export default AppOrders;
