import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import '../../Styles/Orders.css'; // Import the CSS file

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
  authDomain: "hungrie-5717f.firebaseapp.com",
  projectId: "hungrie-5717f",
  storageBucket: "hungrie-5717f.appspot.com",
  messagingSenderId: "121136828215",
  appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
  measurementId: ""
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const WebOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'HungrieStore'));
        const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('firestore data', details);
        setOrders(details);
        setLoading(false); // Stop loading when data is fetched
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false); // Stop loading even if there's an error
      }
    };

    fetchOrders();
  }, []);

  if (loading) {
    return <p className="loading-text">Loading orders...</p>;
  }

  return (
    <div className="orders-container">
      <h1 className="title">Orders from Website</h1>
      {orders.length === 0 ? (
        <p>No orders available.</p>
      ) : (
        <ul className="orders-list">
          {orders.map((order) => (
            <li key={order.id} className="order-item">
              {/* Billing Details */}
              <div className="billing-details">
                <p><strong>Customer:</strong> {order.billingDetails?.firstName} {order.billingDetails?.lastName}</p>
                <p><strong>Address:</strong> {order.billingDetails?.streetAddress}</p>
                <p><strong>Postcode:</strong> {order.billingDetails?.postcode}</p>
              </div>

              {/* Order Total */}
              <p className="order-total">
                <strong>Total Amount: </strong> {order.totalAmount || '0.00'}
              </p>

              {/* Order Items */}
              <ul className="line-items">
                {order.items && order.items.length > 0 ? (
                  order.items.map((item, index) => (
                    <li key={index} className="line-item">
                      <img src={item.image} alt={item.title} className="item-image" />
                      <div>
                        <strong>{item.title}</strong> - {item.category}<br />
                        <span>Price: ₹{item.price}</span><br />
                        <span>Quantity: {item.quantity}</span><br />
                        {item.toppings?.length > 0 && (
                          <span><strong>Toppings:</strong> {item.toppings.join(', ')}</span>
                        )}
                      </div>
                    </li>
                  ))
                ) : (
                  <li>No items available for this order.</li>
                )}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default WebOrders;
