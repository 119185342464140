// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';
import '../Styles/Dashboard.css';
import { AiOutlineProduct } from "react-icons/ai";
import { CgWebsite } from "react-icons/cg";
import { FaKitchenSet } from "react-icons/fa6";
import { MdOutlineCancel, MdHistory, MdAdd } from "react-icons/md";
import { PiInvoice } from "react-icons/pi";
import { RxHamburgerMenu } from "react-icons/rx";
import Orders from './Options/Orders';
import KitchenOrdersList from './Options/KitchenOrders';
import Dinning from './Options/Dinning';
import WebOrders from './Options/Web-Orders';
import AppOrders from './Options/App-Orders';
import Delivered from './Options/Delivered';
import Canceled from './Options/Canceled';
import AddItems from './Options/AddItems';
import Users from './Options/Users';
import Welcome from './Options/Welcome';
import ToDeliver from './Options/ToDeliver'; // Assuming you have this component
import PointofSale from './Options/PointofSale'; // Assuming this component exists
import { useNavigate } from 'react-router-dom';
import UpdateItems from './Options/UpdateItems';
import { CiLogout } from "react-icons/ci";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { RiEBike2Line } from "react-icons/ri";

function Dashboard() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('Main');
  const [toggle, setToggle] = useState(false);
  const [roles, setRoles] = useState([]);
  useEffect(()=>{
    // window.onload = () => {
    //   localStorage.removeItem('roles');
    //   navigate('/');
    // };
  })

  // Fetch roles from localStorage
  useEffect(() => {
    const storedRoles = JSON.parse(localStorage.getItem('roles'));
    if (storedRoles) {
      setRoles(storedRoles);
    } else {
      // If no roles found, redirect to login
      window.location.href = '/'; // Redirect to login page
    }
  }, []);

  // Determine which options to show based on the user's roles
  const renderMenuItems = () => {
    const options = [];
    if (roles.includes('orders' || 'kitchen' || 'dining' ||'website' || 'application' || 'to deliver' || 'delivered' || 'canceled' || 'point of sale' || 'add items' || 'cancel item' || 'add user' || 'update-item')) {
      options.push(
        <li
          key="Main"
          onClick={() => setSelectedOption('Main')}
          className={selectedOption === 'Main' ? 'active' : ''}
        >
          <AiOutlineProduct size={20} /> Welcome
        </li>
      );
    }
    if (roles.includes('orders')) {
      options.push(
        <li
          key="orders"
          onClick={() => setSelectedOption('Orders')}
          className={selectedOption === 'Orders' ? 'active' : ''}
        >
          <AiOutlineProduct size={20} /> Orders
        </li>
      );
    }

    if (roles.includes('kitchen')) {
      options.push(
        <li
          key="kitchen"
          onClick={() => setSelectedOption('KitchenOrders')}
          className={selectedOption === 'KitchenOrders' ? 'active' : ''}
        >
          <FaKitchenSet size={20} /> Kitchen
        </li>
      );
    }

    if (roles.includes('dining')) {
      options.push(
        <li
          key="dining"
          onClick={() => setSelectedOption('Dinning')}
          className={selectedOption === 'Dinning' ? 'active' : ''}
        >
          <FaKitchenSet size={20} /> Dinning
        </li>
      );
    }

    if (roles.includes('website')) {
      options.push(
        <li
          key="web-orders"
          onClick={() => setSelectedOption('Web-Orders')}
          className={selectedOption === 'Web-Orders' ? 'active' : ''}
        >
          <CgWebsite size={20} /> Website Orders
        </li>
      );
    }

    if (roles.includes('application')) {
      options.push(
        <li
          key="app-orders"
          onClick={() => setSelectedOption('App-Orders')}
          className={selectedOption === 'App-Orders' ? 'active' : ''}
        >
          <CgWebsite size={20} /> App Orders
        </li>
      );
    }

    if (roles.includes('to deliver')) {
      options.push(
        <li
          key="to-deliver"
          onClick={() => setSelectedOption('ToDeliver')}
          className={selectedOption === 'ToDeliver' ? 'active' : ''}
        >
          <MdHistory size={20} /> To Deliver
        </li>
      );
    }

    if (roles.includes('delivered')) {
      options.push(
        <li
          key="delivered"
          onClick={() => setSelectedOption('Delivered')}
          className={selectedOption === 'Delivered' ? 'active' : ''}
        >
          <RiEBike2Line size={20} /> Delivered
        </li>
      );
    }

    if (roles.includes('canceled')) {
      options.push(
        <li
          key="canceled"
          onClick={() => setSelectedOption('Canceled-Orders')}
          className={selectedOption === 'Canceled-Orders' ? 'active' : ''}
        >
          <MdOutlineCancel size={20} /> Canceled Orders
        </li>
      );
    }

    if (roles.includes('point of sale')) {
      options.push(
        <li
          key="point-of-sale"
          onClick={() => setSelectedOption('Point-of-sale')}
          className={selectedOption === 'Point-of-sale' ? 'active' : ''}
        >
          <PiInvoice size={20} /> Point of Sale
        </li>
      );
    }

    if (roles.includes('add items')) {
      options.push(
        <li
          key="add-items"
          onClick={() => setSelectedOption('Add-Items')}
          className={selectedOption === 'Add-Items' ? 'active' : ''}
        >
          <MdAdd size={20} /> Add Items
        </li>
      );
    }
    if (roles.includes('update-item')) {
      options.push(
        <li
          key="update-item"
          onClick={() => setSelectedOption('update-item')}
          className={selectedOption === 'update-item' ? 'active' : ''}
        >
          <MdOutlineTipsAndUpdates size={20} /> Update Items
        </li>
      );
    }

    if (roles.includes('cancel item')) {
      options.push(
        <li
          key="cancel-item"
          onClick={() => setSelectedOption('Cancel-Item')}
          className={selectedOption === 'Cancel-Item' ? 'active' : ''}
        >
          <MdOutlineCancel size={20} /> Cancel Item
        </li>
      );
    }
    if (roles.includes('add user')) {
      options.push(
        <li
          key="add-user"
          onClick={() => setSelectedOption('add-user')}
          className={selectedOption === 'add-user' ? 'active' : ''}
        >
          <IoIosAddCircleOutline size={20} /> Add User
        </li>
      );
    }
    <li
    onClick={() => {
      localStorage.removeItem('roles');  // Remove 'roles' from localStorage
      navigate('/');  // Navigate to the home page or another route
  }}
  
    // className={selectedOption === 'add-user' ? 'active' : ''}
  >
    <CiLogout  size={20} /> Logout
  </li>
 if (roles.includes('orders' || 'kitchen' || 'dining' ||'website' || 'application' || 'to deliver' || 'delivered' || 'canceled' || 'point of sale' || 'add items' || 'cancel item' || 'add user' || 'update-item')) {
  options.push(
    <li
      key="Main"
      onClick={() => {
        localStorage.removeItem('roles');  // Remove 'roles' from localStorage
        navigate('/');  // Navigate to the home page or another route
    }}
          className={selectedOption === 'Maisn' ? 'active' : ''}
    >
      <AiOutlineProduct size={20} /> Logout
    </li>
  );
}
   
    return options;
  };

  return (
    <div className="dashboard-wrapper">
      <div className="hamburger">
        <RxHamburgerMenu onClick={() => setToggle(!toggle)} />
      </div>

      {toggle && (
        <div className="sidebar-2">
          <div className="cross" onClick={() => setToggle(false)}>x</div>
          <h3>Hungrie</h3>
          <ul>
            {renderMenuItems()}
          </ul>
        </div>
      )}

      <div className="sidebar">
        <img src="logo2.png" alt="Logo" style={{ height: '120px', width: '160px', marginLeft: '25px' }} />
        <ul>
          {renderMenuItems()}
        </ul>
      </div>

      <div className="main-content">
      {selectedOption === 'Main' && <Welcome />}
        {selectedOption === 'Orders' && <Orders />}
        {selectedOption === 'KitchenOrders' && <KitchenOrdersList />}
        {selectedOption === 'Dinning' && <Dinning />}
        {selectedOption === 'Web-Orders' && <WebOrders />}
        {selectedOption === 'App-Orders' && <AppOrders />}
        {selectedOption === 'Delivered' && <Delivered />}
        {selectedOption === 'Canceled-Orders' && <Canceled />}
        {selectedOption === 'ToDeliver' && <ToDeliver />}
        {selectedOption === 'Point-of-sale' && <PointofSale />}
        {selectedOption === 'Add-Items' && <AddItems />}
        {selectedOption === 'Cancel-Item' && <Canceled />} {/* Assuming you want this functionality */}
        {selectedOption === 'add-user' && <Users />}
        {selectedOption === 'update-item' && <UpdateItems />}
      </div>
    </div>
  );
}

export default Dashboard;
