import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import '../../Styles/Orders.css';
import axios from 'axios';
// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
    authDomain: "hungrie-5717f.firebaseapp.com",
    projectId: "hungrie-5717f",
    storageBucket: "hungrie-5717f.appspot.com",
    messagingSenderId: "121136828215",
    appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
    measurementId: ""
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const AppOrders = () => {
  const [firestoreDetails, setFirestoreDetails] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFirestoreDetails = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'CanceledOrders'));
        const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('Fetched order details:', details); // Log the fetched details
        setFirestoreDetails(details);
      } catch (error) {
        console.error('Error fetching Firestore details:', error);
      } finally {
        setLoading(false);
      }
    };
    const fetchOrders = async () => {
      const siteUrl = 'https://hungrie.co/wp-json/wc/v3/orders';
      const consumerKey = 'ck_1d5e7805a44bf1ce36dcc9b441e66a6a445f6e5a';
      const consumerSecret = 'cs_d70cd4fc4e9413c05e509ad8f97772dcdc051539';
    
      try {
        const response = await axios.get(siteUrl, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
          params: {
            per_page: 10,
          },
        });
    
        // Filter orders to only include those with a status of 'canceled'
        const canceledOrders = response.data.filter(order => order.status === 'canceled');
    
        console.log('Fetched Orders canceled:', canceledOrders); // Log fetched canceled orders
        setOrders(canceledOrders); // Set state to only include canceled orders
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    
    fetchOrders()
    fetchFirestoreDetails();
  }, []);

  const formatTimestamp = (timestamp) => {
    return timestamp ? new Date(timestamp.seconds * 1000).toString() : 'N/A';
  };

  return (
    <div className="orders-container">
      <h1 className="title">Orders from App</h1>
      {loading ? (
        <p className="loading-text">Loading orders...</p>
      ) : (
        <ul className="orders-list">
          {firestoreDetails.map((order) => {
            const { orderDetails } = order; // Extracting orderDetails

            // Check if orderDetails exists
            if (!orderDetails) {
              return (
                <li key={order.id} className="order-item">
                  <p>Order ID: #{order.id} - Details not available.</p>
                </li>
              );
            }

            return (
              <li key={order.id} className="order-item">
                <p className="order-details">
                  Order ID: <span className="order-number">#{order.id}</span> - Total: <span className="total">{orderDetails.totalPrice} {orderDetails.currency || 'USD'}</span> - Status: <span className="status">{orderDetails.status}</span>
                </p>
                <p><strong>Name:</strong> {orderDetails.Name || 'N/A'}</p>
                <p><strong>Email:</strong> {orderDetails.Email || 'N/A'}</p>
                <p><strong>Phone:</strong> {orderDetails.Phone || 'N/A'}</p>
                <p>
                  <strong>Address:</strong> {`${orderDetails.Address || 'N/A'}, ${orderDetails.City || 'N/A'}, ${orderDetails.State || 'N/A'}, ${orderDetails.Postal || 'N/A'}`}
                </p>
                {/* <p><strong>Accepted At:</strong> {formatTimestamp(order.acceptedAt)}</p>
                <p><strong>Canceled At:</strong> {formatTimestamp(order.canceledAt)}</p>
                <p><strong>Delivery Time:</strong> {orderDetails.deliveryTime || 'N/A'} minutes</p> */}
                <ul className="line-items">
                {(Array.isArray(orderDetails.cartItems) ? orderDetails.cartItems : orderDetails.items).map((item, index) => (

                    
                    <li key={index} className="line-item">
                      <span style={{ fontWeight: 'bold' }}>{item.title || 'N/A'}</span> - Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span> - Price: <span className="item-price">{item.price}</span>
                      {item.toppings && item.toppings.length > 0 && (
                        <div>
                          <strong>Toppings:</strong> {item.toppings.join(', ')}
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
            );
          })}
                   <ul className="orders-list">
            {orders.map((order) => (
              <li key={order.id} className="order-item">
                <p className="order-details">
                  Order <span className="order-number">#{order.id}</span> - Total: <span className="total">{order.total} {order.currency}</span> - Status: <span className="status">{order.status}</span>
                </p>
                <ul className="line-items">
                  {order.line_items.map((item) => (
                    <li key={item.id} className="line-item">
                      <span style={{fontWeight:'bold'}}>{item.name}</span> - Quantity: <span style={{fontWeight:'bold'}}>{item.quantity}</span> - Price: <span className="item-price">{item.price}</span>
                    </li>
                  ))}
                </ul>
                {/* <div className="buttons">
                  <button className="status-button on-hold" onClick={() => updateOrderStatus(order.id, 'on-hold')}>On Hold</button>
                  <button className="status-button completed" onClick={() => updateOrderStatus(order.id, 'completed')}>To Deliver</button>
                </div> */}
              </li>
            ))}
          </ul>
        </ul>
        
      )}
    </div>
  );
};

export default AppOrders;
