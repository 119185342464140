import React, { useEffect, useState } from 'react'; 
import { initializeApp } from 'firebase/app';

import '../../Styles/Orders.css'; // Assuming you have a CSS file for styles


import { getFirestore, collection, getDocs, doc, writeBatch,updateDoc,setDoc,deleteDoc } from 'firebase/firestore';
import axios from 'axios';
const firebaseConfig = {
    apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
    authDomain: "hungrie-5717f.firebaseapp.com",
    projectId: "hungrie-5717f",
    storageBucket: "hungrie-5717f.appspot.com",
    messagingSenderId: "121136828215",
    appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
    measurementId: ""
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const KitchenOrdersList = () => {
    const [kitchenOrders, setKitchenOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error] = useState(null);
    const [firestoreDetails] = useState([]);
    const [orders] = useState([]);
    
    // const [orders, setOrders] = useState([]);
    const [notifications, setNotifications] = useState(() => {
        const savedNotifications = localStorage.getItem('deliveryNotifications');
        return savedNotifications ? JSON.parse(savedNotifications) : {};
    });
    useEffect(() => {
        // const fetchOrders = async () => {
        //     const siteUrl = 'https://hungrie.co/wp-json/wc/v3/orders';
        //     const consumerKey = 'ck_1d5e7805a44bf1ce36dcc9b441e66a6a445f6e5a';
        //     const consumerSecret = 'cs_d70cd4fc4e9413c05e509ad8f97772dcdc051539';
    
        //     try {
        //         const response = await axios.get(siteUrl, {
        //             auth: {
        //                 username: consumerKey,
        //                 password: consumerSecret,
        //             },
        //             params: {
        //                 per_page: 10,
        //             },
        //         });
        //         console.log('Fetched Orders:', response.data);
        //         return response.data; // Return the orders
        //     } catch (error) {
        //         console.error('Error fetching orders:', error);
        //         return []; // Return an empty array on error
        //     }
        // };
        const fetchOrders = async () => {
            try {
                const querySnapshot = await getDocs(collection(firestore, 'HungrieStore'));
                const response = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                console.log('firestore data', response);
                return response; 
             // Stop loading when data is fetched
              } catch (error) {
                console.error('Error fetching orders:', error);
                setLoading(false); // Stop loading even if there's an error
              }
          };
        const fetchFirestoreDetails = async () => {
            try {
                const querySnapshot = await getDocs(collection(firestore, 'OrderDetails'));
                const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                console.log('Firestore Details:', details);
                return details; // Return the details
            } catch (error) {
                console.error('Error fetching Firestore details:', error);
                return []; // Return an empty array on error
            }
        };
    
        const fetchKitchenOrders = async () => {
            try {
                const querySnapshot = await getDocs(collection(firestore, 'KitchenOrders'));
                const orders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                orders.sort((a, b) => b.sequenceNumber - a.sequenceNumber);
                console.log('Fetched Kitchen Orders:', orders);
                
                return orders; // Return the kitchen orders
            } catch (error) {
                console.error('Error fetching KitchenOrders:', error);
                return []; // Return an empty array on error
            }
        };
    
        const combineAndSaveOrders = async (kitchenOrders, allOrders) => {
            console.log('Orders:', orders);
            console.log('Firestore Details:', firestoreDetails);
        
            if (allOrders.length === 0) {
                console.warn('No orders to combine. Exiting combineAndSaveOrders.');
                return;
            }
        
            // Ensure the next sequence number is calculated correctly
            let highestSequenceNumber = 0; // Default to 0 if no orders exist
        
            // If kitchenOrders is not empty, find the highest sequence number
            if (kitchenOrders.length > 0) {
                highestSequenceNumber = kitchenOrders.reduce((max, order) => {
                    return Math.max(max, order.sequenceNumber || 0);
                }, 0);
            }
        
            console.log('Highest Sequence Number:', highestSequenceNumber);
        
            // Set the starting sequence number to be highest + 1
            let currentSequenceNumber = highestSequenceNumber + 1;
        
            const kitchenOrdersRef = collection(firestore, 'KitchenOrders');
            const batch = writeBatch(firestore);
        
            allOrders.forEach(order => {
                // Check if order already exists based on unique ID
                const exists = kitchenOrders.some(kOrder => kOrder.id === order.id);
        
                if (!exists) {
                    const orderRef = doc(kitchenOrdersRef);
                    batch.set(orderRef, {
                        ...order,
                        sequenceNumber: currentSequenceNumber, // Assign the current sequence number
                    });
                    console.log(`Saving Order ID ${order.id} with Sequence Number: ${currentSequenceNumber}`);
                    currentSequenceNumber++; // Increment sequence number for the next order
                } else {
                    console.log(`Order ID ${order.id} already exists. Skipping...`);
                }
            });
        
            try {
                await batch.commit();
                console.log('Combined orders saved successfully.');
            } catch (error) {
                console.error('Error saving combined orders:', error);
            }
        };
        
        
    
        const fetchData = async () => {
            try {
                const [ordersResponse, firestoreDetailsResponse, kitchenOrders] = await Promise.all([
                    fetchOrders(),
                    fetchFirestoreDetails(),
                    fetchKitchenOrders(),
                ]);
        
                console.log('Fetched Kitchen Orders:', kitchenOrders);
        
                // Combine the data
                const allOrders = [...ordersResponse, ...firestoreDetailsResponse];
                console.log('Combined Orders:', allOrders);
        
                // Update state with combined orders
                setKitchenOrders(kitchenOrders); // Update state with kitchen orders
        
                // Proceed to combine and save
                await combineAndSaveOrders(kitchenOrders, allOrders);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        
    
        fetchData(); // Start the data fetching process
    }, []); // Empty dependency array to run only once on mount
     // Empty dependency array to run only once on mount
      

    


    if (loading) {
        return <p>Loading kitchen orders...</p>;
    }

    if (error) {
        return <p>Error loading kitchen orders: {error}</p>;
    }

    


    
    const handleToDeliver = async (order) => {
        const toDeliverRef = collection(firestore, 'ToDeliver');
        const orderData = { ...order, status: 'to-deliver' };

        try {
            await setDoc(doc(toDeliverRef), orderData);
            console.log('Order added to To Deliver:', orderData);
            const newNotification = `Order #${order.id} added to delivery.`;
            setNotifications(prev => {
                const updatedNotifications = { ...prev, [order.id]: newNotification };
                localStorage.setItem('deliveryNotifications', JSON.stringify(updatedNotifications));
                return updatedNotifications;
            });
        } catch (error) {
            console.error('Error adding order to To Deliver:', error);
        }
    };

 
    
    
    
    function Refresh() {
        window.location.reload();
    }
    
    
    
    return (
        <div className="orders-container">
  
            <h1 className="title">Orders for Kitchen - <span onClick={()=>Refresh()} style={{border:'1px solid silver',fontSize:'20px',padding:'10px',cursor:'pointer'}}>Reload</span></h1>
            <p style={{textAlign:"center"}}>Please check orders in order to refresh the orders in kitchen </p>
            <ul className="orders-list">
                
                {kitchenOrders.map(order => (
                      <>
                      
                    <li key={order.id} className="order-item">
                    {notifications[order.id] && (
                            <div className="notification" style={{display:'flex',alignItems:'center',gap:'5px'}}>
                                <p style={{fontSize:'20px',color:'red'}}>{notifications[order.id]}</p>
                                {/* <button onClick={() => clearNotification(order.id)} style={{height:'fit-content',backgroundColor:'#c84347',color:'white'}}>Clear</button> */}
                            </div>
                        )}
                        <p style={{ textDecoration: 'underline' }}>
                            Sequence Number: <span style={{ fontWeight: 'bold' }}>{order.sequenceNumber}</span>
                        </p>
                        <p className="order-details">
                            Order <span className="order-number">#{order.id}</span> - Total: <span className="total">{order.total || order.totalPrice || order.totalAmount} {order.currency}</span> - Status: <span className="status">{order.status}</span>
                        </p>
                        <ul>
                        
                        {(Array.isArray(order.cartItems) && order.cartItems.length > 0) || (Array.isArray(order.items) && order.items.length > 0) ? (
     (order.cartItems || order.items).map((item) => (
            <li key={item.id} className="line-item">
                {/* <span style={{ fontWeight: 'bold' }}>{item.title}</span> - Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span> - Price: <span className="item-price">{item.price}</span> */}
                <p>Title: <span style={{ fontWeight: 'bold' }}>{item.title}</span></p>
                                        <p>Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span></p>
                                        <p>Flavor: <span style={{ fontWeight: 'bold' }}>{item.flavor || 'none'}</span></p>
                                        <p>Price: <span style={{ fontWeight: 'bold' }}>{item.price}</span></p>
                {/* Check for toppings and render them if available */}
                {Array.isArray(item.toppings) && item.toppings.length > 0 ? (
                    <div className="item-toppings">
                        <h4>Toppings:</h4>
                        <ul>
                            {item.toppings.map((topping, index) => (
                                <li key={index}>{topping}</li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <p>No toppings available.</p>
                )}

                {/* Check for options and render them if available */}
                {item.options && Object.keys(item.options).length > 0 && (
                    <div className="item-options">
                        <h4>Options:</h4>
                        <ul>
                            {Object.entries(item.options).map(([option, value]) => (
                                <li key={option}>
                                    <strong>{option}:</strong> {value ? 'Yes' : 'No'}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </li>
        ))
    ) : (
        <p>No items in this order.</p>
    )}




                            {order.line_items && order.line_items.length > 0 ? (
                                order.line_items.map(item => (
                                    <li key={item.id} className="line-item" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        
                                        {/* <img src={item.image.src} alt={item.name} style={{ maxWidth: '100px', marginBottom: '10px' }} /> */}
                                        <li key={item.id} className="line-item">
                                        <p>Title: <span style={{ fontWeight: 'bold' }}>{item.name}</span></p>
                                        <p>Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span></p>
                                        <p>Price: <span style={{ fontWeight: 'bold' }}>{item.price}</span></p>
</li>
                                        {/* Displaying Topping Options, excluding _ywapo_meta_data */}
                                        {item.meta_data && item.meta_data.length > 0 ? (
                                            <div className="topping-options">
                                                <h4>Topping Options:</h4>
                                                <ul>
                                                    {item.meta_data
                                                        .filter(meta => meta.key !== '_ywapo_meta_data') // Exclude unwanted key
                                                        .map((meta, index) => (
                                                            <li key={index}>
                                                                <strong>{meta.display_key}:</strong> {String(meta.display_value)}
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        ) : (
                                            <p>No topping options available.</p>
                                        )}

                                        {/* Button to show billing information */}
                                        
                                    </li>
                                ))
                            ) : (
                                <p></p>
                            )}
                        </ul>
                        <div className="buttons">

<button className="status-button completed" onClick={()=> handleToDeliver(order) }>
    To Deliever </button>
                  </div>
                    </li>
                    
                
                  
                  </>
                ))}
            </ul>


        </div>
    );
};

export default KitchenOrdersList;
