// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, getDocs } from 'firebase/firestore';
// import '../../Styles/Orders.css'; // Import the CSS file

// // Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
//     authDomain: "hungrie-5717f.firebaseapp.com",
//     projectId: "hungrie-5717f",
//     storageBucket: "hungrie-5717f.appspot.com",
//     messagingSenderId: "121136828215",
//     appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
//     measurementId: ""
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const firestore = getFirestore(app);

// const Orders = () => {
//   const [orders, setOrders] = useState([]);
//   const [firestoreDetails, setFirestoreDetails] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchOrders = async () => {
//       const siteUrl = 'https://hungrie.co/wp-json/wc/v3/orders';
//       const consumerKey = 'ck_1d5e7805a44bf1ce36dcc9b441e66a6a445f6e5a';
//       const consumerSecret = 'cs_d70cd4fc4e9413c05e509ad8f97772dcdc051539';

//       try {
//         const response = await axios.get(siteUrl, {
//           auth: {
//             username: consumerKey,
//             password: consumerSecret,
//           },
//           params: {
//             per_page: 10,
//           },
//         });
//         setOrders(response.data);
//       } catch (error) {
//         console.error('Error fetching orders:', error);
//       }
//     };

//     const fetchFirestoreDetails = async () => {
//       try {
//         const querySnapshot = await getDocs(collection(firestore, 'OrderDetails'));
//         const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//         console.log('firestore data', details);
//         setFirestoreDetails(details);
//       } catch (error) {
//         console.error('Error fetching Firestore details:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchOrders();
//     fetchFirestoreDetails();
//   }, []);

//   const updateOrderStatus = async (orderId, status) => {
//     const siteUrl = `https://hungrie.co/wp-json/wc/v3/orders/${orderId}`;
//     const consumerKey = 'ck_1d5e7805a44bf1ce36dcc9b441e66a6a445f6e5a';
//     const consumerSecret = 'cs_d70cd4fc4e9413c05e509ad8f97772dcdc051539';

//     try {
//       await axios.put(siteUrl, { status }, {
//         auth: {
//           username: consumerKey,
//           password: consumerSecret,
//         },
//       });
//       setOrders((prevOrders) =>
//         prevOrders.map((order) =>
//           order.id === orderId ? { ...order, status } : order
//         )
//       );
//     } catch (error) {
//       console.error('Error updating order status:', error);
//     }
//   };

//   return (
//     <div className="orders-container">
//       <h1 className="title">Orders Management</h1>
//       {loading ? (
//         <p className="loading-text">Loading orders...</p>
//       ) : (
//         <>
//           <ul className="orders-list">
//             {orders.map((order) => (
//               <li key={order.id} className="order-item">
//                 <p className="order-details">
//                   Order <span className="order-number">#{order.id}</span> - Total: <span className="total">{order.total} {order.currency}</span> - Status: <span className="status">{order.status}</span>
//                 </p>
//                 <ul className="line-items">
//                   {order.line_items.map((item) => (
//                     <li key={item.id} className="line-item">
//                       <span style={{fontWeight:'bold'}}>{item.name}</span> - Quantity: <span style={{fontWeight:'bold'}}>{item.quantity}</span> - Price: <span className="item-price">{item.price}</span>
//                     </li>
//                   ))}
//                 </ul>
//                 <div className="buttons">
//                   <button className="status-button on-hold" onClick={() => updateOrderStatus(order.id, 'on-hold')}>On Hold</button>
//                   <button className="status-button completed" onClick={() => updateOrderStatus(order.id, 'completed')}>Delivered</button>
//                 </div>
//               </li>
//             ))}
//           </ul>

//           {/* <h2 className="sub-title">Firestore Data</h2> */}
//           <ul className="firestore-list">

//           </ul>
//           <ul className="orders-list">
//             {firestoreDetails.map((order) => (
//               <li key={order.id} className="order-item">
//                 <p className="order-details">
//                   Order <span className="order-number">#{order.id}</span> - Total: <span className="total">{order.totalPrice} {order.currency}</span> - Status: <span className="status">{order.status}</span>
//                 </p>
//                 <ul className="line-items">
//                   {order.cartItems.map((item) => (
//                     <li key={item.id} className="line-item">
//                       <span style={{fontWeight:'bold'}}>{item.title}</span> - Quantity: <span style={{fontWeight:'bold'}}>{item.quantity}</span> - Price: <span className="item-price">{item.price}</span>
//                     </li>
//                   ))}
//                 </ul>
//                 <div className="buttons">
//                   <button className="status-button on-hold" onClick={() => updateOrderStatus(order.id, 'on-hold')}>On Hold</button>
//                   <button className="status-button completed" onClick={() => updateOrderStatus(order.id, 'completed')}>Delivered</button>
//                 </div>
//               </li>
//             ))}
//           </ul>
//         </>
//       )}
//     </div>
//   );
// };

// export default Orders;



import React, { useEffect, useState } from 'react'; 
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import '../../Styles/Orders.css';

const firebaseConfig = {
  apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
  authDomain: "hungrie-5717f.firebaseapp.com",
  projectId: "hungrie-5717f",
  storageBucket: "hungrie-5717f.appspot.com",
  messagingSenderId: "121136828215",
  appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
  measurementId: ""
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [firestoreDetails, setFirestoreDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Web, setWeb] = useState(true);
  const [App, setApp] = useState(true);
  const [filters, setFilters] = useState({
    showCompleted: false,
    showPending: true,
    showAll: false, // Default to showing all orders
  });
  const [sortBy, setSortBy] = useState('dateNewest'); // Default sorting by date (newest first)

  useEffect(() => {
    // Fetch Orders from Firestore
    const fetchOrders = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'HungrieStore'));
        const details = querySnapshot.docs.map(doc => {
          const order = { id: doc.id, ...doc.data() };

          // Ensure createdAt exists and is a Firestore Timestamp (which has seconds)
          if (order.createdAt && order.createdAt.seconds !== undefined) {
            return order;
          } else {
            // If `createdAt` is not available or malformed, we can handle it gracefully
            order.createdAt = { seconds: 0 }; // Assign a default timestamp or handle accordingly
            return order;
          }
        });

        // Sort orders by createdAt (newest first) if `createdAt` is properly set
        const sortedOrders = details.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
        setOrders(sortedOrders);
        console.log(sortedOrders);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false); // Stop loading even if there's an error
      }
    };

    // Fetch Firestore details from another collection (e.g., OrderDetails)
    const fetchFirestoreDetails = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'OrderDetails'));
        const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setFirestoreDetails(details);
        console.log('app', details);
      } catch (error) {
        console.error('Error fetching Firestore details:', error);
      } finally {
        setLoading(false);
      }
    };

    // Fetch data from both collections
    const fetchData = async () => {
      await Promise.all([fetchOrders(), fetchFirestoreDetails()]);
    };

    // Initial data fetch
    fetchData();

    // Set up an interval to refresh data every 2 seconds
    const intervalId = setInterval(fetchData, 2000); // Refresh every 2000 ms (2 seconds)

    // Cleanup interval when the component unmounts
    return () => clearInterval(intervalId);

  }, []); 
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setFilters(prevState => {
      const newFilters = { ...prevState, [name]: checked };

      // If both Completed and Pending are unchecked, show All
      if (!newFilters.showCompleted && !newFilters.showPending) {
        newFilters.showAll = true;
      } else {
        newFilters.showAll = false;
      }

      return newFilters;
    });
  };

  useEffect(() => {
    const { showCompleted, showPending, showAll } = filters;

    let filtered = [...orders];

    if (showAll) {
      setFilteredOrders(filtered); // Show all orders if "Show All" is selected
    } else {
      if (showCompleted) {
        filtered = filtered.filter(order => order.status === 'completed');
      }

      if (showPending) {
        filtered = filtered.filter(order => order.status === "pending");
      }

      setFilteredOrders(filtered);
    }
  }, [filters, orders]);

  // Sorting function
  const handleSortChange = (e) => {
    const sortValue = e.target.value; // Get the selected value
    setSortBy(sortValue); // Update the sorting value

    let sortedOrders = [...filteredOrders];

    // Toggle between Web and App based on selected option
    if (sortValue === "webOrders") {
      setWeb(true);
      setApp(false);
    } else if (sortValue === "appOrders") {
      setWeb(false);
      setApp(true);
    } else {
      // Sorting logic
      if (sortValue === 'dateNewest') {
        sortedOrders = sortedOrders.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds); // Sort by recent date
      } else if (sortValue === 'dateOldest') {
        sortedOrders = sortedOrders.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds); // Sort by oldest date
      } else if (sortValue === 'priceLowToHigh') {
        sortedOrders = sortedOrders.sort((a, b) => a.totalAmount - b.totalAmount); // Sort by price low to high
      } else if (sortValue === 'priceHighToLow') {
        sortedOrders = sortedOrders.sort((a, b) => b.totalAmount - a.totalAmount); // Sort by price high to low
      }
    }

    // After sorting, update filtered orders
    setFilteredOrders(sortedOrders);
  };

  return (
    <div className="orders-container">
      <h1 className="title">Orders Management</h1>

      <div className="filter-checkboxes">
        <div style={{ display: 'flex' }}>
          <label className="checkbox-label">
            <input 
              type="checkbox" 
              name="showPending" 
              checked={filters.showPending} 
              onChange={handleCheckboxChange} 
              className="filter-checkbox"
            />
            <span className="checkbox-text">Pending</span>
          </label>
          <label className="checkbox-label">
            <input 
              type="checkbox" 
              name="showCompleted" 
              checked={filters.showCompleted} 
              onChange={handleCheckboxChange} 
              className="filter-checkbox"
            />
            <span className="checkbox-text">Completed</span>
          </label>

          <label className="checkbox-label">
            <input 
              type="checkbox" 
              name="showAll" 
              checked={filters.showAll} 
              onChange={handleCheckboxChange} 
              className="filter-checkbox"
            />
            <span className="checkbox-text"> All</span>
          </label>
        </div>
        <div className="sort-dropdown">
          <label htmlFor="sortOrders" style={{ color: '#c84347', fontSize: '20px' }}>Sort by: </label>
          <select id="sortOrders" value={sortBy} onChange={handleSortChange} style={{ border: '1px solid #c84347', height: '30px' }}>
            <option value="dateNewest">Date: Newest to Oldest</option>
            <option value="dateOldest">Date: Oldest to Newest</option>
            <option value="priceLowToHigh">Price: Low to High</option>
            <option value="priceHighToLow">Price: High to Low</option>
            <option value="webOrders">Website Orders</option>
            <option value="appOrders">App Orders</option>
          </select>
        </div>
      </div>

      {loading ? (
        <p className="loading-text">Loading orders...</p>
      ) : (
        <>
          {Web ? (
            <ul className="orders-list">
              {Array.isArray(filteredOrders) && filteredOrders.map((order) => (
                <li key={order.id} className="order-item">
                  <p className="order-details">
                    Order <span className="order-number">#{order.id}</span> - 
                    Total: <span className="total">{order.totalAmount} {order.currency}</span> - 
                    <span className="status">{order.billingDetails?.status || 'not available'}</span>

                  </p>
                  <p className="order-date">Created At: {new Date(order.createdAt.seconds * 1000).toLocaleString()}</p> {/* Format the timestamp */}
                  <ul className="line-items">
                    {Array.isArray(order.items) && order.items.map((item, index) => (
                      <li key={index} className="line-item">
                        <div className="for-img">
                          <div>
                            <strong>{item.title}</strong> - {item.size}<br />
                            Price: {item.price} - Quantity: {item.quantity}<br />
                            {item.toppings?.length > 0 && (
                              <span><strong>Toppings:</strong> {item.toppings.join(', ')}</span>
                            )}
                          </div>
                          <img src={item.image} alt={item.title} className="item-image" />
                        </div>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          ) : null}

          {App ? (
            <ul className="firestore-list" style={{ listStyle: 'none' }}>
              {Array.isArray(firestoreDetails) && firestoreDetails.map((order) => (
                <li key={order.id} className="order-item">
                  <p className="order-details">
                    Order <span className="order-number">#{order.id}</span> - Total: <span className="total">{order.totalPrice || order.totalAmount} {order.currency}</span> - Status: <span className="status">{order.status}</span>
                  </p>
                  <ul className="line-items">
                    {Array.isArray(order.cartItems) && order.cartItems.map((item) => (
                      <li key={item.id} className="line-item">
                        <span style={{ fontWeight: 'bold' }}>{item.title}</span> - Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span> - Price: <span className="item-price">{item.price}</span>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Orders;
