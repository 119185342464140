import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import '../../Styles/Orders.css'; // Import the CSS file

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
    authDomain: "hungrie-5717f.firebaseapp.com",
    projectId: "hungrie-5717f",
    storageBucket: "hungrie-5717f.appspot.com",
    messagingSenderId: "121136828215",
    appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
    measurementId: ""
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const AppOrders = () => {
  const [firestoreDetails, setFirestoreDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFirestoreDetails = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'OrderDetails'));
        const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('firestore data', details);
        setFirestoreDetails(details);
      } catch (error) {
        console.error('Error fetching Firestore details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFirestoreDetails();
  }, []);

  return (
    <div className="orders-container">
      <h1 className="title">Orders from App</h1>
      {loading ? (
        <p className="loading-text">Loading orders...</p>
      ) : (
        <>
          <ul className="orders-list">
            {firestoreDetails.map((order) => (
              <li key={order.id} className="order-item">
                <p className="order-details">
                  Order <span className="order-number">#{order.id}</span> - Total: <span className="total">{order.totalPrice} {order.currency}</span> - Status: <span className="status">{order.status}</span>
                </p>
                <ul className="line-items">
                  {order.cartItems && order.cartItems.length > 0 ? (
                    order.cartItems.map((item) => (
                      <li key={item.id} className="line-item">
                        <span style={{fontWeight:'bold'}}>{item.title}</span> - Quantity: <span style={{fontWeight:'bold'}}>{item.quantity}</span> - Price: <span className="item-price">{item.price}</span>
                      </li>
                    ))
                  ) : (
                    <li>No items in this order.</li>
                  )}
                </ul>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default AppOrders;
