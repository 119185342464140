// import React, { useEffect, useState } from 'react';
// import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, getDocs } from 'firebase/firestore';
// import { jsPDF } from 'jspdf';
// import 'jspdf-autotable';
// import '../../Styles/Orders.css';

// // Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
//     authDomain: "hungrie-5717f.firebaseapp.com",
//     projectId: "hungrie-5717f",
//     storageBucket: "hungrie-5717f.appspot.com",
//     messagingSenderId: "121136828215",
//     appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
//     measurementId: ""
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const firestore = getFirestore(app);

// const Orders = () => {
//     const [firestoreDetails, setFirestoreDetails] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [cart, setCart] = useState([]);
//     const [totalPrice, setTotalPrice] = useState(0);
//     const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//     const [pdfGenerated, setPdfGenerated] = useState(false);
//     const [pdfBlob, setPdfBlob] = useState(null);
//     const [selectedSize, setSelectedSize] = useState(null);
//     const [sizePrice, setSizePrice] = useState(0);
//     const [isPopupOpen, setIsPopupOpen] = useState(false);
//     const [currentPizza, setCurrentPizza] = useState(null);

//     useEffect(() => {
//         const fetchFirestoreDetails = async () => {
//             try {
//                 const querySnapshot = await getDocs(collection(firestore, 'MenuItems'));
//                 const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//                 setFirestoreDetails(details);
//             } catch (error) {
//                 console.error('Error fetching Firestore details:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchFirestoreDetails();
//     }, []);

//     useEffect(() => {
//         const total = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
//         setTotalPrice(total);
//     }, [cart]);

//     const addItemToCart = (item) => {
//         if (item.category === 'Pizza') {
//             setCurrentPizza(item);
//             setIsPopupOpen(true);
//         } else {
//             setCart(prevCart => {
//                 const existingItem = prevCart.find(cartItem => cartItem.id === item.id);
//                 if (existingItem) {
//                     return prevCart.map(cartItem =>
//                         cartItem.id === item.id
//                             ? { ...cartItem, quantity: cartItem.quantity + 1 }
//                             : cartItem
//                     );
//                 } else {
//                     return [...prevCart, { ...item, quantity: 1 }];
//                 }
//             });
//         }
//     };

//     const confirmSizeSelection = () => {
//         if (currentPizza && selectedSize) {
//             const itemToAdd = {
//                 ...currentPizza,
//                 price: sizePrice,
//                 quantity: 1,
//                 selectedSize: selectedSize
//             };
//             setCart(prevCart => {
//                 const existingItem = prevCart.find(cartItem => cartItem.id === itemToAdd.id && cartItem.selectedSize === selectedSize);
//                 if (existingItem) {
//                     return prevCart.map(cartItem =>
//                         cartItem.id === existingItem.id && cartItem.selectedSize === selectedSize
//                             ? { ...cartItem, quantity: cartItem.quantity + 1 }
//                             : cartItem
//                     );
//                 } else {
//                     return [...prevCart, itemToAdd];
//                 }
//             });
//         }
//         setIsPopupOpen(false);
//     };

//     const removeItemFromCart = (itemToRemove) => {
//         setCart(cart.filter(item => item.id !== itemToRemove.id));
//     };

//     const toggleSidebar = () => {
//         setIsSidebarOpen(!isSidebarOpen);
//     };

//     const filteredItems = firestoreDetails.filter(item =>
//         item.title.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     const generatePDF = () => {
//         const doc = new jsPDF();
//         const customerId = `CustomerID_${Date.now()}`;

//         // Title
//         doc.setFontSize(22);
//         doc.text('Hungrie Restaurant', 10, 10);
//         doc.setFontSize(16);
//         doc.text(`Invoice for ${customerId}`, 10, 20);
//         doc.setFontSize(12);
//         doc.text('Items:', 10, 30);

//         // Table setup
//         const tableColumn = ["Item", "Size", "Quantity", "Price"];
//         const tableRows = [];

//         cart.forEach(item => {
//             const price = typeof item.price === 'number' ? item.price.toFixed(2) : 'N/A';
//             tableRows.push([item.title, item.selectedSize, item.quantity, `${(item.price * item.quantity).toFixed(2)}`]);
//         });

//         // Add table to PDF
//         doc.autoTable(tableColumn, tableRows, { startY: 40 });

//         // Total Price
//         doc.text(`Total Price: Rs ${totalPrice.toFixed(2)}`, 10, doc.lastAutoTable.finalY + 10);
        
//         // Create a blob and store it
//         const pdfOutput = doc.output('blob');
//         setPdfBlob(pdfOutput);
//         setPdfGenerated(true);
//     };

//     const viewPDF = () => {
//         if (pdfBlob) {
//             const url = URL.createObjectURL(pdfBlob);
//             window.open(url, '_blank');
//         }
//     };

//     return (
//         <div className="orders-container">
//             <h1 className="orders-title">Point of sale </h1>
//             <div className="search-container">
//                 <input
//                     type="text"
//                     placeholder="Search items..."
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                     className="search-input"
//                 />
//                 <button onClick={toggleSidebar} className="toggle-cart-button">
//                     {isSidebarOpen ? 'Close List' : 'Open List'}
//                 </button>
//             </div>
//             {loading ? (
//                 <p className="loading-text">Loading orders...</p>
//             ) : (
//                 <>
//                     <ul className="item-list">
//                         {filteredItems.map((item) => (
//                             <li key={item.id} className="item-card">
//                                 <img src={item.image} alt={item.title} className="item-image" />
//                                 <h2 className="item-title">{item.title}</h2>
//                                 <p className="item-prices">Price: Rs. {typeof item.price === 'number' ? item.price.toFixed(2) : 'N/A'}</p>
//                                 <button onClick={() => addItemToCart(item)} className="add-to-cart-button">Add</button>
//                             </li>
//                         ))}
//                     </ul>

//                     {/* Popup for Pizza Size Selection */}
//                     {isPopupOpen && (
//                         <div className="popup-overlay">
//                             <div className="popup-content">
//                                 <h2>Select Size for {currentPizza.title}</h2>
//                                 {currentPizza.sizes.map((size, index) => (
//                                     <div key={index} className="size-option">
//                                         <input
//                                             type="radio"
//                                             id={`size-${index}`}
//                                             name="size"
//                                             value={size.size}
//                                             onChange={() => {
//                                                 setSelectedSize(size.size);
//                                                 setSizePrice(size.price);
//                                             }}
//                                         />
//                                         <label htmlFor={`size-${index}`}>{size.size} - Rs. {size.price}</label>
//                                     </div>
//                                 ))}
//                                  <div style={{display:'flex'}}>
//                                 <button onClick={confirmSizeSelection} className="add-to-cart-button">Confirm</button>
//                                 <button onClick={() => setIsPopupOpen(false)} className="add-to-cart-button">Cancel</button>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {/* Sidebar for cart */}
//                     <div className={`cart-sidebar ${isSidebarOpen ? 'open' : ''}`}>
//                         <h2 className="cart-title">Cart</h2>
//                         <button onClick={toggleSidebar} className="close-sidebar-button">✖</button>
//                         <ul className="cart-items-list">
//                             {cart.map((item) => (
//                                 <li key={item.id} className="cart-item">
//                                     {item.title} - Size: {item.selectedSize} - Quantity: {item.quantity} - Price: Rs{typeof item.price === 'number' ? (item.price * item.quantity).toFixed(2) : 'N/A'}
//                                     <button onClick={() => removeItemFromCart(item)} className="remove-item-button">✖</button>
//                                 </li>
//                             ))}
//                         </ul>
//                         <p className="total-price">Total Price: ${totalPrice.toFixed(2)}</p>
//                         <div style={{display:'flex'}}>
//                             <button onClick={generatePDF} className="add-to-cart-button">Generate PDF</button>
//                             {pdfGenerated && (
//                                 <button onClick={viewPDF} className="add-to-cart-button">View PDF</button>
//                             )}
//                         </div>
//                     </div>
//                 </>
//             )}
//         </div>
//     );
// };

// export default Orders;


import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs,addDoc } from 'firebase/firestore';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import '../../Styles/Orders.css';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
    authDomain: "hungrie-5717f.firebaseapp.com",
    projectId: "hungrie-5717f",
    storageBucket: "hungrie-5717f.appspot.com",
    messagingSenderId: "121136828215",
    appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
    measurementId: ""
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const Orders = () => {
    const [firestoreDetails, setFirestoreDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [cart, setCart] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [pdfGenerated, setPdfGenerated] = useState(false);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null);
    const [sizePrice, setSizePrice] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [currentPizza, setCurrentPizza] = useState(null);
    const [amountPaid, setAmountPaid] = useState(0);
    const [balance, setBalance] = useState(0);
    const [customerName, setCustomerName] = useState('');
    const [changePdf, setchangePdf] = useState(true);
    const [changeAdd, setchangeAdd] = useState(true);
    const [tableNumber, setTableNumber] = useState('');
    const [password, setPassword] = useState('');
const [isPasswordPromptVisible, setIsPasswordPromptVisible] = useState(false);
const [itemToRemove, setItemToRemove] = useState(null);
const [passwordError, setPasswordError] = useState('');

const [isAddItemVisible, setIsAddItemVisible] = useState(false);
const [Scroll, setScroll] = useState(false);
const [discount, setDiscount] = useState(0); // New state for discount
const [promoCode, setPromoCode] = useState(''); // New state for promo code

// Update the useEffect for total price calculation
useEffect(() => {
    const total = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setTotalPrice(total - discount); // Apply discount to total
}, [cart, discount]);

// Add the applyPromoCode function
const applyPromoCode = async () => {
    const promoRef = collection(firestore, 'Promo');
    const querySnapshot = await getDocs(promoRef);
    let found = false;

    querySnapshot.docs.forEach(doc => {
        const promoData = doc.data();
        if (promoData.code === promoCode) {
            setDiscount((totalPrice * promoData.percent) / 100); // Calculate discount
            found = true;
            alert(`Promo code applied! You get a ${promoData.percent}% discount.`);
        }
    });

    if (!found) {
        alert('Invalid promo code.');
        setDiscount(0); // Reset discount if not found
    }
};


    const [sauces, setSauces] = useState([
        { name: 'BBQ Sauce', price: 20, quantity: 0 },
        { name: 'Hot Sauce', price: 15, quantity: 0 },
        { name: 'Garlic Sauce', price: 10, quantity: 0 },
    ]);
    const [toppings, setToppings] = useState([
        { name: 'Cheese', price: 30, quantity: 0 },
        { name: 'Mushrooms', price: 25, quantity: 0 },
        { name: 'Olives', price: 20, quantity: 0 },
    ]);

    useEffect(() => {
        const fetchFirestoreDetails = async () => {
            try {
                // Fetch data from 'MenuItems' collection
                const menuItemsSnapshot = await getDocs(collection(firestore, 'MenuItems'));
                const menuItems = menuItemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
                // Fetch data from 'Deals' collection
                const dealsSnapshot = await getDocs(collection(firestore, 'Deals'));
                const deals = dealsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
                // Combine the results of both collections
                const details = [...menuItems, ...deals];
        
                setFirestoreDetails(details);
                console.log(details);
            } catch (error) {
                console.error('Error fetching Firestore details:', error);
            } finally {
                setLoading(false);
            }
        };
        

        fetchFirestoreDetails();
    }, []);

    useEffect(() => {
        const total = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
        const saucesTotal = sauces.reduce((acc, sauce) => acc + sauce.price * sauce.quantity, 0);
        const toppingsTotal = toppings.reduce((acc, topping) => acc + topping.price * topping.quantity, 0);
        setTotalPrice(total + saucesTotal + toppingsTotal);
        setBalance(total + saucesTotal + toppingsTotal - amountPaid);
    }, [cart, amountPaid, sauces, toppings]);

    const addItemToCart = (item) => {
         console.log(item.category)
        if (item.category === "Pizza") {
            setCurrentPizza(item);
            setIsPopupOpen(true);
        } else {
            setCart(prevCart => {
                const existingItem = prevCart.find(cartItem => cartItem.id === item.id);
                if (existingItem) {
                    return prevCart.map(cartItem =>
                        cartItem.id === item.id
                            ? { ...cartItem, quantity: cartItem.quantity + 1 }
                            : cartItem
                    );
                } else {
                    return [...prevCart, { ...item, quantity: 1 }];
                }
            });
        }
    };

    const handlePaymentChange = (event) => {
        const paidAmount = parseFloat(event.target.value) || 0;
        setAmountPaid(paidAmount);
    };

    const confirmSizeSelection = () => {
        if (currentPizza && selectedSize) {
            const itemToAdd = {
                ...currentPizza,
                price: sizePrice,
                quantity: 1,
                selectedSize: selectedSize
            };
    
            setCart(prevCart => {
                // Check if a pizza with the same id and size already exists in the cart
                const existingItem = prevCart.find(cartItem => 
                    cartItem.id === itemToAdd.id && cartItem.selectedSize === selectedSize
                );
    
                if (existingItem) {
                    // If it exists, increase the quantity for that pizza
                    return prevCart.map(cartItem =>
                        cartItem.id === existingItem.id && cartItem.selectedSize === selectedSize
                            ? { ...cartItem, quantity: cartItem.quantity + 1 }
                            : cartItem
                    );
                } else {
                    // If it doesn't exist, add the new pizza to the cart
                    return [...prevCart, itemToAdd];
                }
            });
        }
        setIsPopupOpen(false);
    };
    

    const removeItemFromCart = (itemToRemove) => {
        setCart(cart.filter(item => item.id !== itemToRemove.id));
    };
    const handleRemoveItemClick = (item) => {
        setItemToRemove(item); // Store the item to be removed
        setIsPasswordPromptVisible(true); // Show the password prompt
    };
    
    const handlePasswordSubmit = () => {
        const correctPassword = "123456"; 
        if (password === correctPassword) {
            removeItemFromCart(itemToRemove);
            setIsPasswordPromptVisible(false);
            setPassword(''); 
            setPasswordError('');
        } else {
            setPasswordError('Incorrect password');
        }
    };
    
    const changeItemQuantity = (item, change) => {
        setCart(prevCart => {
            const existingItem = prevCart.find(cartItem => cartItem.id === item.id);
            if (existingItem) {
                const newQuantity = existingItem.quantity + change;
                if (newQuantity <= 0) {
                    return prevCart.filter(cartItem => cartItem.id !== item.id);
                }
                return prevCart.map(cartItem =>
                    cartItem.id === item.id ? { ...cartItem, quantity: newQuantity } : cartItem
                );
            }
            return prevCart;
        });
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // Function to check if the search term is a shortcut (i.e., a number after ':')
    const isShortcutSearch = (term) => term.startsWith(':') && !isNaN(term.substring(1));


    // Filter the items based on the search term
 // Filter the items based on the search term
const filteredItems = firestoreDetails.filter(item => {
    // If the search term is a shortcut number (e.g., :123), match by Shortcut (id)
    if (isShortcutSearch(searchTerm)) {
        const shortcutNumber = searchTerm.substring(1); // Get the number after ":"
        console.log('Searching for Shortcut:', shortcutNumber);

        // Ensure the Shortcut field exists and matches the search term
        return item.Shortcut && item.Shortcut.toString() === shortcutNumber;
    }

    // Regular search by title or category (case-insensitive)
    const titleMatches = item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase());
    const categoryMatches = item.category && item.category.toLowerCase().includes(searchTerm.toLowerCase());

    return titleMatches || categoryMatches;
});


    const generatePDF = () => {
        setchangePdf(false)
        setInterval(()=>{ setchangePdf(true) }, 1500)
        const doc = new jsPDF();
        const customerId = `Invoice-${new Date().getTime()}`;
        const date = new Date().toLocaleDateString();
        const taxRate = 0.10;
        const subtotal = totalPrice;
        const tax = subtotal * taxRate;
        const total = subtotal + tax;
    
        // Logo and Title
        doc.setFillColor(204, 0, 0);
        doc.rect(0, 0, 210, 30, 'F');
        doc.setTextColor(255, 255, 255);
        doc.setFontSize(22);
        doc.text("INVOICE", 10, 20);
    
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        doc.text(`Invoice To: ${customerName || "Customer"}`, 10, 40);
        doc.text("Main Road, Building Name,", 10, 45);
        doc.text("Country", 10, 50);
        doc.text(`Invoice: ${customerId}`, 150, 40);
        doc.text(`Date: ${date}`, 150, 45);
    
        // Table columns and rows based on cart items
        const tableColumns = ["SL.", "ITEM DESCRIPTION", "PIZZA TYPE", "PRICE", "QTY.", "TOTAL"];
        const tableRows = cart.map((item, index) => [
            index + 1,
            `${item.title} - ${item.selectedSize || ''}`,
            item.selectedPizzaType, // Add Pizza Type here
            `Rs. ${item.price.toFixed(2)}`,
            item.quantity,
            `Rs. ${(item.price * item.quantity).toFixed(2)}`
        ]);
    
        // Adding the table
        doc.autoTable({
            startY: 60,
            head: [tableColumns],
            body: tableRows,
            theme: 'grid',
            headStyles: { fillColor: [204, 0, 0], textColor: 255 },
            styles: { halign: 'center' }
        });
    
        // Adding subtotal, tax, and total section
        const finalY = doc.lastAutoTable.finalY + 10;
        doc.setFontSize(12);
        doc.text(`SUB-TOTAL: Rs. ${subtotal.toFixed(2)}`, 150, finalY);
        doc.text(`TAX: ${(taxRate * 100).toFixed(1)}%`, 150, finalY + 5);
        doc.setFontSize(14);
        doc.text(`TOTAL: Rs. ${total.toFixed(2)}`, 150, finalY + 15);
    
        // Footer
        doc.setFontSize(10);
        doc.text("Thank you for your business", 10, finalY + 30);
        doc.text("Terms & Conditions: Payment is due within 15 days.", 10, finalY + 35);
        doc.text("Authorized Sign", 150, finalY + 35);
    
        // Generate Blob and Update State
        const pdfBlob = doc.output("blob");
        setPdfBlob(pdfBlob);
        setInterval(() => {});
        setPdfGenerated(true);
    };
    
    
    const viewPDF = () => {
        setPdfGenerated(false)
        if (pdfBlob) {
            const url = URL.createObjectURL(pdfBlob);
            window.open(url, '_blank');
        }
    };
    

    const addSauce = (sauce) => {
        setSauces(sauces.map(s =>
            s.name === sauce.name ? { ...s, quantity: s.quantity + 1 } : s
        ));
    };

    const removeSauce = (sauce) => {
        setSauces(sauces.map(s =>
            s.name === sauce.name && s.quantity > 0 ? { ...s, quantity: s.quantity - 1 } : s
        ));
    };

    const addTopping = (topping) => {
        setToppings(toppings.map(t =>
            t.name === topping.name ? { ...t, quantity: t.quantity + 1 } : t
        ));
    };

    const removeTopping = (topping) => {
        setToppings(toppings.map(t =>
            t.name === topping.name && t.quantity > 0 ? { ...t, quantity: t.quantity - 1 } : t
        ));
    };
    const getTotalItemsInCart = () => {
        return cart.reduce((acc, item) => acc + item.quantity, 0);
    };
    const handleAddToDining = async () => {
        try {
            const orderData = {
                tableNumber,
                items: cart,
                totalPrice,
                date: new Date().toISOString()
            };
            await addDoc(collection(firestore, 'Dinning'), orderData);
            alert('Order added to Dinning collection!');
            setTableNumber('');
            setScroll(false)
            // setCart([]);
        } catch (error) {
            console.error('Error adding order to Firestore:', error);
        }
    };
    const highlightText = (text, search) => {
        if (!search) return text; // If no search term, return the original text
        const parts = text.split(new RegExp(`(${search})`, 'gi')); // Split text by search term, case insensitive
        return parts.map((part, index) => 
            part.toLowerCase() === search.toLowerCase() 
                ? <span key={index} style={{ color: '#c84347',fontWeight:'bold' }}>{part}</span> 
                : part
        );
    };
    
    const handlePizzaTypeChange = (item, pizzaType) => {
        const updatedCart = cart.map(cartItem => {
            // Check if both id and selectedSize match to ensure only the targeted item is updated
            if (cartItem.id === item.id && cartItem.selectedSize === item.selectedSize) {
                return { ...cartItem, selectedPizzaType: pizzaType };
            }
            return cartItem;
        });
    
        setCart(updatedCart); // Update the cart state
    };
    
    
    
    return (
        <div className="orders-container">
            <h1 className="orders-title">Point of Sale</h1>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search items with title or with special number with ( : ) eg- ( :123 )"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
                 <button onClick={toggleSidebar} className="toggle-cart-button">
                {isSidebarOpen ? 'Close List' : `Open List (${getTotalItemsInCart()})`}
            </button>
            </div>
            {loading ? (
                <p className="loading-text">Loading orders...</p>
            ) : (
                <>
                    <ul className="item-list">
                                {firestoreDetails.filter(item => item.title.toLowerCase().includes(searchTerm.toLowerCase())).map(item => (
                            <li key={item.id} className="item-card">
                                <img src={item.image} alt={item.title} className="item-image" />
                                <h2 className="item-title">
    {highlightText(item.title, searchTerm)}
</h2>
                                <p className="item-prices">Price: Rs. {typeof item.price === 'number' ? item.price.toFixed(2) : 'N/A'}</p>
                                
                                <button onClick={() => addItemToCart(item)} className="add-to-cart-button">Add</button>
                            </li>
                        ))}
                    </ul>

                    {/* Popup for Pizza Size Selection */}
                    {isPopupOpen && (
    <div className="popup-overlay">
        <div className="popup-content">
            <button className="close-button" onClick={() => setIsPopupOpen(false)}>✖</button>
            <h2>Select Size for {currentPizza.title}</h2>
            {currentPizza.sizes.map((size, index) => (
                <div key={index} className="size-option">
                    <input
                        type="radio"
                        id={`size-${index}`}
                        name="size"
                        value={size.size}
                        onChange={() => {
                            setSelectedSize(size.size);
                            setSizePrice(size.price);
                        }}
                    />
                    <label htmlFor={`size-${index}`}>{size.size} - Rs. {size.price}</label>
                </div>
            ))}
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
                <button onClick={confirmSizeSelection} className="add-to-cart-button">Confirm</button>
                <button onClick={() => setIsPopupOpen(false)} className="add-to-cart-button">Cancel</button>
            </div>
        </div>
    </div>
)}


                    {/* Sidebar for cart */}
                    <div className={`cart-sidebar ${isSidebarOpen ? 'open' : ''}`}  style={{ marginBottom:'100px' }}>
                        <h2 className="cart-title">Cart</h2>
                        <button onClick={toggleSidebar} className="close-sidebar-button">✖</button>
                        <ul className="cart-items-list">
                        {cart.map((item, index) => (
  <div key={`${item.id}-${item.selectedSize}-${index}`}> {/* Ensure unique key */}
    <li className="cart-item">
      {item.title} - Size: {item.selectedSize} 
      - Price: Rs {typeof item.price === 'number' ? (item.price * item.quantity).toFixed(2) : 'N/A'}
      <div style={{ display: 'flex', gap: '5px' }}>
        <button onClick={() => changeItemQuantity(item, -1)} className="quantity-button">-</button>
        {item.quantity}
        <button onClick={() => changeItemQuantity(item, 1)} className="quantity-button">+</button>
      </div>
      <button onClick={() => handleRemoveItemClick(item)} className="remove-item-button">✖</button>
    </li>

    {/* Conditionally render Pizza type selection */}
    {item.category === 'Pizza' && (
      <div>
        <label>Pizza Type: </label>
        <select 
          value={item.selectedPizzaType} 
          onChange={(e) => handlePizzaTypeChange(item, e.target.value)} 
        >
          <option value="Crust">Crust</option>
          <option value="No Crust">No Crust</option>
        </select>
      </div>
    )}
  </div>
))}



                        </ul>

                        <h3>Extra Sauces</h3>
                        {sauces.map(sauce => (
                            <div key={sauce.name} className="sauce-item">
                                <span>{sauce.name} - Rs. {sauce.price}</span>
                                <div style={{display:'flex',gap:'5px'}}>
                                <button onClick={() => addSauce(sauce)} className="quantity-button">+</button>
                                {sauce.quantity}
                                <button onClick={() => removeSauce(sauce)} className="quantity-button">-</button>
                                    </div>
                                
                            </div>
                        ))}

                        <h3>Extra Toppings</h3>
                        {toppings.map(topping => (
                            <div key={topping.name} className="topping-item">
                                <span>{topping.name} - Rs. {topping.price}</span>
                                <div style={{display:'flex',gap:'5px'}}>
                                <button onClick={() => addTopping(topping)} className="quantity-button">+</button>
                                {topping.quantity}
                                <button onClick={() => removeTopping(topping)} className="quantity-button">-</button>
                                </div>
                            </div>
                        ))}

                        <p className="total-price">Total Price: Rs {totalPrice.toFixed(2)}</p>
                        <div className="payment-section">
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                            <label>Customer Name:</label>
                            <input 
                                type="text" 
                                value={customerName} 
                                onChange={(e) => setCustomerName(e.target.value)} 
                                className="customer-input"
                                style={{ backgroundColor: '#C84347', width: '120px', border: '1px solid white', color: 'white' }}
                            />
                            </div>
                            <div style={{display:'flex',justifyContent:'space-between',flexDirection:'column'}}>
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                <label>Any Voucher:</label>
                                <input
    type="text"
    placeholder="Enter promo code"
    value={promoCode}
    onChange={(e) => setPromoCode(e.target.value)} // Correctly capture the event
    onBlur={applyPromoCode} // Call the apply function when the input loses focus
    style={{ backgroundColor: '#C84347', width: '120px', border: '1px solid white', color: 'white' }}
    
/>


                                </div>
                           </div>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                            <label>Amount Paid:</label>
                            <input 
                                type="number" 
                                value={amountPaid} 
                                onChange={handlePaymentChange} 
                                className="payment-input"
                                style={{ backgroundColor: '#C84347', width: '120px', border: '1px solid white', color: 'white' }}
                            />
                            </div>
                          
                          
                            <p>Remaining Balance: Rs {balance.toFixed(2)}</p>
                        </div>

                        <div style={{ display: 'flex'}}>
                            <button onClick={generatePDF}  className="add-to-cart-button" >

                                {
                                    changePdf?
                                    'Generated Pdf' : 'Generated'
                                }
                            </button>
                            {pdfGenerated && (
                                <button onClick={viewPDF} className="add-to-cart-button" style={{borderLeft:'1px solid silver'}}>View PDF</button>
                            )}
                        </div>
                        <button onClick={() => {setIsAddItemVisible(!isAddItemVisible) ;setScroll(true) }}  className="add-to-cart-button" style={{ display: 'flex',marginTop:'5px', }}>
    Also add Items to Dinning ?
</button>
{
    Scroll ? 
    <div style={{fontSize:'12px',padding:'5px'}}> Please scroll down and add table number </div>:null
}
<button onClick={() =>  setCart([])}  className="add-to-cart-button" style={{ display: 'flex',marginTop:'5px',marginBottom:'20px' }}>
    Clear Items
</button>
{isAddItemVisible && (
    <div className="add-dining-form" style={{ display: 'flex',flexDirection:'column',marginTrim:'5px',marginBottom:'50px' }}>
        <label>Table Number:</label>
        <input 
            type="text" 
            value={tableNumber} 
            onChange={(e) => setTableNumber(e.target.value)} 
        />
        <button onClick={handleAddToDining}>Submit</button>
    </div>
)}

                    </div>
 
                </>
            )}
            {isPasswordPromptVisible && (
    <div className="password-prompt">
        <div className="password-prompt-content">
            <h3 style={{color:'black'}}>Enter Password to Remove Item</h3>
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                style={{width:'80%'}}
            />
            {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
            <div style={{ display: 'flex', gap: '10px' }}>
                <button onClick={handlePasswordSubmit} style={{width:'50%'}}>Submit</button>
                <button onClick={() => setIsPasswordPromptVisible(false)} style={{width:'50%'}}>Cancel</button>
            </div>
        </div>
    </div>
)}

        </div>
    );
};

export default Orders;
