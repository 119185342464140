import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import NotAllowed from './Components/Options/NotAllowed';
function App() {


  return (
    <Router>
      <Routes>
        <Route path="/" Component={Login} />
        <Route path="/dashboard" Component={Dashboard} />
        <Route path="/not-allowed" Component={NotAllowed} />
      </Routes>
    </Router>
  );
}

export default App;
