// src/WelcomeScreen.js
import React from 'react';
 // Make sure to create this CSS file

const WelcomeScreen = () => {
  return (
    <div className="welcome-container">
      <div className="welcome-content">
        <h1 className="welcome-title">Welcome to the Dashboard!</h1>
        <p className="welcome-description">Manage your orders efficiently and stay on top of your business.</p>
        {/* <button className="cta-button">Get Started</button> */}
      </div>
    </div>
  );
};

export default WelcomeScreen;
