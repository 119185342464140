import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, query, where, getDocs, doc, updateDoc, deleteDoc,getDoc } from 'firebase/firestore';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
  authDomain: "hungrie-5717f.firebaseapp.com",
  projectId: "hungrie-5717f",
  storageBucket: "hungrie-5717f.appspot.com",
  messagingSenderId: "121136828215",
  appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
  measurementId: ""
};

// Initialize Firebase app and Firestore
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const Users = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [updateEmail, setUpdateEmail] = useState('');
  const [updatePassword, setUpdatePassword] = useState('');
  const [updateRoles, setUpdateRoles] = useState([]);
  const [foundUser, setFoundUser] = useState(null); // Define setFoundUser state

  const options = [
    'orders', 'kitchen', 'dining', 'website', 'application', 
    'to deliver', 'delivered', 'canceled', 'point of sale', 
    'add items', 'cancel item' ,'add-user' , 'update-item'
  ];
  const options2 = [
    'orders', 'kitchen', 'dining', 'website', 'application', 
    'to deliver', 'delivered', 'canceled', 'point of sale', 
    'add items', 'cancel item','add-user' , 'update-item'
  ];
  // Handle adding a user
  const handleAddUser = async (e) => {
    e.preventDefault();
    if (!email || !password || selectedOptions.length === 0) {
      alert('Please fill in all fields.');
      return;
    }

    try {
      await addDoc(collection(firestore, 'AccessUsers'), {
        email,
        password, // Store securely in production!
        roles: selectedOptions,
        createdAt: new Date()
      });
      alert('User added successfully!');
      setEmail('');
      setPassword('');
      setSelectedOptions([]);
    } catch (error) {
      console.error('Error adding user: ', error);
      alert('Error adding user.');
    }
  };

  // Handle searching for and updating or deleting a user
  const handleSearchUser = async (e) => {
    e.preventDefault();
    
    if (!updateEmail) {
      alert('Please enter an email to search');
      return;
    }

    const userRef = collection(firestore, 'AccessUsers');
    const q = query(userRef, where('email', '==', updateEmail));
    
    try {
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0]; // Getting the first user matching the query
        setFoundUser(userDoc.data()); // Set found user data
        setUpdateRoles(userDoc.data().roles); // Set roles for the found user
      } else {
        alert('User not found!');
        setFoundUser(null); // Clear any previous found user
      }
    } catch (error) {
      console.error('Error searching for user: ', error);
      alert('Error searching for user');
    }
  };

  // Handle updating a user
// Handle updating a user
// Handle updating a user
// Handle updating a user
// Handle updating a user
const handleUpdateUser = async (e) => {
    e.preventDefault();
  
    if (!updateEmail) {
      alert('Please enter a valid email.');
      return;
    }
  
    try {
      // Query Firestore to find the user by email
      const userRef = collection(firestore, 'AccessUsers');
      const q = query(userRef, where('email', '==', updateEmail));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        alert('User not found!');
        return;
      }
  
      const userDoc = querySnapshot.docs[0]; // Get the first matching document
      const userDocRef = userDoc.ref; // Get reference to the user document
  
      // Completely replace the roles with the new roles selected
      const updatedRoles = [...new Set(updateRoles)]; // Ensure no duplicates in the roles
  
      // Update the user document with the new roles and password
      await updateDoc(userDocRef, {
        password: updatePassword,  // Update the password
        roles: updatedRoles,       // Replace all roles with the new ones
      });
  
      alert('User updated successfully!');
      setUpdateEmail('');           // Reset email field
      setUpdatePassword('');        // Reset password field
      setUpdateRoles([]);           // Clear roles after successful update
      setFoundUser(null);           // Clear found user data
    } catch (error) {
      console.error('Error updating user: ', error);
      alert('Error updating user.');
    }
  };
  
  
  
  
  
  

  // Handle deleting a user
// Handle deleting a user
const handleDeleteUser = async (e) => {
    e.preventDefault();
  
    if (!updateEmail) {
      alert('Please enter a valid email.');
      return;
    }
  
    try {
      // Query Firestore to find the user by email
      const userRef = collection(firestore, 'AccessUsers');
      const q = query(userRef, where('email', '==', updateEmail));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        alert('User not found!');
        return;
      }
  
      // Get the document reference of the first matching document
      const userDoc = querySnapshot.docs[0];
      const userDocRef = userDoc.ref;
  
      // Delete the user document from Firestore
      await deleteDoc(userDocRef);
  
      alert('User deleted successfully!');
      setUpdateEmail(''); // Clear email field
      setUpdatePassword(''); // Clear password field
      setUpdateRoles([]); // Clear roles field
      setFoundUser(null); // Clear found user
    } catch (error) {
      console.error('Error deleting user: ', error);
      alert('Error deleting user.');
    }
  };
  

  // Handle checkbox changes for role selection (Add User & Update User)
// Handle checkbox changes for role selection (Add User & Update User)
const handleCheckboxChange = (event, role) => {
    const updatedRoles = event.target.checked
      ? [...selectedOptions, role] // Add role if checked
      : selectedOptions.filter((option) => option !== role); // Remove role if unchecked
  
    setSelectedOptions(updatedRoles); // Update selectedOptions state
  };
  
  // Handle checkbox changes for role selection (Add User & Update User)
const handleCheckboxChange2 = (event, role) => {
    const updatedRoles = event.target.checked
      ? [...updateRoles, role]  // Add role if checked
      : updateRoles.filter((option) => option !== role); // Remove role if unchecked
  
    setUpdateRoles([...new Set(updatedRoles)]); // Ensure only unique roles are stored
  };
  

  const handleSelectChange = (e) => {
    const { options } = e.target;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedOptions(selected);
  };

  // Modal for selecting roles
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleOpenModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCloseModal2 = () => {
    setIsModalOpen2(false);
  };
  // Modal for Update form to select roles
// Modal for Update form to select roles
const handleUpdateRolesChange = (e) => {
    const { options } = e.target;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setUpdateRoles(selected); // Set the selected roles to updateRoles state
  };
  
  return (
    <div style={{ width: '90%', margin: '0 auto', padding: '20px' }}>
      <h1 style={{ textAlign: 'center', color: '#c84347', marginBottom: '20px' }}>Add User</h1>

      {/* Add User Form */}
      <form onSubmit={handleAddUser} style={formStyle}>
        <div>
          <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: '100%', padding: '10px', marginBottom: '20px', borderRadius: '5px', border: '1px solid #ccc', fontSize: '14px' }}
            required
          />
        </div>

        <div>
          <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ width: '100%', padding: '10px', marginBottom: '20px', borderRadius: '5px', border: '1px solid #ccc', fontSize: '14px' }}
            required
          />
        </div>

        <div>
          <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Select Roles:</label>
          <button type="button" onClick={handleOpenModal} style={{ padding: '10px', backgroundColor: '#c84347', color: '#fff', border: 'none', borderRadius: '5px', fontSize: '14px' ,marginBottom:'10px' }}>
            Select Roles
          </button>
        </div>

        <button type="submit" style={{ width: '100%', padding: '12px', backgroundColor: '#c84347', color: '#fff', border: 'none', borderRadius: '5px', fontSize: '16px', cursor: 'pointer' }}>Add User</button>
      </form>

      {/* Update/Delete User Form */}
      <h1 style={{ textAlign: 'center', color: '#c84347', marginBottom: '20px' }}>Update or Delete User</h1>
      <form onSubmit={handleSearchUser} style={formStyle}>
        <div>
          <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px', display: 'block' }}>Enter Email to Search:</label>
          <input
            type="email"
            value={updateEmail}
            onChange={(e) => setUpdateEmail(e.target.value)}
            style={{ width: '100%', padding: '10px', marginBottom: '20px', borderRadius: '5px', border: '1px solid #ccc', fontSize: '14px' }}
            required
          />
        </div>
        <button type="submit" style={{ padding: '12px', backgroundColor: '#c84347', color: '#fff', border: 'none', borderRadius: '5px', fontSize: '16px'}}>
          Search User
        </button>
      </form>

      {/* If a user is found, display their details */}
      {foundUser && (
        <div style={{ marginTop: '20px', padding: '15px', border: '1px solid #ccc', borderRadius: '5px' }} >
         

          {/* Update or Delete User */}
          <form onSubmit={handleUpdateUser} style={formStyle}>
          <h3 style={{color:'black'}}>User Found</h3>
          <p>Email: {foundUser.email}</p>
          <p>Roles: {foundUser.roles.join(', ')}</p>
            <div>
              <label>Update Password:</label>
              <input
                type="password"
                value={updatePassword}
                onChange={(e) => setUpdatePassword(e.target.value)}
                style={{ marginBottom: '10px', padding: '10px', width: '100%' }}
              />
            </div>

            <div style={{display:'flex',flexDirection:'column'}}>
              <label >Update Roles:</label>
              <button type="button" onClick={handleOpenModal2} style={{marginTop:'10px', width:'120px', padding: '10px', backgroundColor: '#c84347', color: '#fff', border: 'none', borderRadius: '5px', fontSize: '14px' }}>
                Select Roles
              </button>
              {/* <select
                multiple
                value={updateRoles}
                onChange={handleUpdateRolesChange}
                style={{ width: '100%', padding: '10px', marginBottom: '20px' }}
              >
                {options.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </select> */}
            </div>
            <div>
            <button type="submit" style={{width:'50%', backgroundColor: '#c84347', color: '#fff', border: 'none', padding: '12px',marginTop:'10px' }}>
              Update User
            </button>
            <button onClick={handleDeleteUser} style={{ width:'50%',backgroundColor: '#f44336', color: '#fff', border: 'none', padding: '12px', marginTop: '10px' }}>
            Delete User
          </button>
            </div>
            
          </form>

        
        </div>
      )}

      {/* Modal for Role Selection */}
      {isModalOpen && (
        <div style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '5px',
            width: '300px',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          }}>
            <h3>Select Roles</h3>
            {options.map((role) => (
  <div key={role} style={{ marginBottom: '10px' }}>
    <input
      type="checkbox"
      id={role}
      checked={selectedOptions.includes(role)}
      onChange={(e) => handleCheckboxChange(e, role)}
    />
    <label htmlFor={role} style={{ marginLeft: '8px' }}>{role}</label>
  </div>
))}

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <button type="button" onClick={handleCloseModal} style={{ backgroundColor: '#ccc', color: '#333', border: 'none', padding: '10px', borderRadius: '5px' }}>
                Cancel
              </button>
              <button type="button" onClick={handleCloseModal} style={{ backgroundColor: '#c84347', color: '#fff', border: 'none', padding: '10px', borderRadius: '5px' }}>
                Done
              </button>
            </div>
          </div>
        </div>
      )}
          
{isModalOpen2 && (
  <div style={{
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <div style={{
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '5px',
      width: '300px',
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    }}>
      <h3>Select Roles</h3>
      {options2.map((role) => (
        <div key={role} style={{ marginBottom: '10px' }}>
          <input
            type="checkbox"
            id={role}
            checked={updateRoles.includes(role)}  // Check if role is already selected
            onChange={(e) => handleCheckboxChange2(e, role)}
          />
          <label htmlFor={role} style={{ marginLeft: '8px' }}>{role}</label>
        </div>
      ))}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <button type="button" onClick={handleCloseModal2} style={{ backgroundColor: '#ccc', color: '#333', border: 'none', padding: '10px', borderRadius: '5px' }}>
          Cancel
        </button>
        <button type="button" onClick={handleCloseModal2} style={{ backgroundColor: '#c84347', color: '#fff', border: 'none', padding: '10px', borderRadius: '5px' }}>
          Done
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default Users;
const formStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    marginBottom: '20px',
    
};