import React, { useEffect, useState } from 'react'; 
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import '../../Styles/Orders.css'; // Assuming you have a CSS file for styles
import { doc, updateDoc,getDoc,setDoc,deleteDoc  } from 'firebase/firestore';
import axios from 'axios';
const firebaseConfig = {
    apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
    authDomain: "hungrie-5717f.firebaseapp.com",
    projectId: "hungrie-5717f",
    storageBucket: "hungrie-5717f.appspot.com",
    messagingSenderId: "121136828215",
    appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
    measurementId: ""
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const KitchenOrdersList = () => {
    const [kitchenOrders, setKitchenOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedBilling, setSelectedBilling] = useState(null);
    const [selectedBilling2, setSelectedBilling2] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchKitchenOrders = async () => {
            try {
                const querySnapshot = await getDocs(collection(firestore, 'ToDeliver'));
                const orders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                
                // Sort orders by sequence number
                orders.sort((a, b) => b.sequenceNumber - a.sequenceNumber);
                
                console.log('Fetched ToDeliever Orders:', orders);
                setKitchenOrders(orders);
            } catch (err) {
                console.error('Error fetching ToDeliever:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchKitchenOrders();
    }, []);

    const handleShowModal = (billing , order) => {
        if (billing) {
            setSelectedBilling(billing);
        } else {
            setSelectedBilling2(order); // Assuming 'order' is accessible here
        }
        setShowModal(true);
    };
    const handleShowModal2 = (order) => {
        setSelectedBilling2(order);
       
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedBilling(null);
    };

    if (loading) {
        return <p>Loading kitchen orders...</p>;
    }

    if (error) {
        return <p>Error loading kitchen orders: {error}</p>;
    }
    const updateOrderStatus2 = async (sequenceNumber, status) => {
        try {
            console.log('Attempting to update order with Sequence Number:', sequenceNumber);
            
            // Fetch all orders to find the document with the specific sequenceNumber
            const querySnapshot = await getDocs(collection(firestore, 'ToDeliver'));
            const orderToUpdate = querySnapshot.docs.find(doc => {
                const orderData = doc.data();
                return orderData.sequenceNumber === sequenceNumber; // Compare sequence numbers
            });
    
            if (!orderToUpdate) {
                console.error('No such document with Sequence Number:', sequenceNumber);
                return; // Exit if no matching document is found
            }
    
            const orderRef = doc(firestore, 'ToDeliver', orderToUpdate.id); // Get the reference using the found document's ID
            
            // Update the order status in "ToDeliver"
            await updateDoc(orderRef, { status });
    
            // After updating the status, move the order to the "Completed" collection
            const completedOrdersRef = collection(firestore, 'CompletedOrders');
            const newCompletedOrderRef = doc(completedOrdersRef); // Create a new document reference in "Completed"
            
            // Add the updated order to "Completed"
            await setDoc(newCompletedOrderRef, {
                ...orderToUpdate.data(), // Copy all the fields from the existing order
                status, // Make sure the status is updated
                sequenceNumber, // Retain the sequence number
                completedAt: new Date(), // Add the completed timestamp
            });
    
            // Now, delete the order from the "ToDeliver" collection
            await deleteDoc(orderRef);
    
            // Update the local state to reflect the change
            setKitchenOrders(prevOrders =>
                prevOrders.filter(order => order.sequenceNumber !== sequenceNumber) // Remove the order from state
            );
    
            console.log('Order moved to Completed and deleted from ToDeliver.');
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };
    


    
    
    
    
    
    
    return (
        <div className="orders-container">
  
            <h1 className="title">Orders to Deliver</h1>
            <p style={{textAlign:"center"}}>Please check orders in order to refresh the orders in kitchen </p>
            <ul className="orders-list">
                
                {kitchenOrders.map(order => (
                      <>
                    <li key={order.id} className="order-item">
                        <p style={{ textDecoration: 'underline' }}>
                            Sequence Number: <span style={{ fontWeight: 'bold' }}>{order.sequenceNumber}</span>
                        </p>
                        <p className="order-details">
                            Order <span className="order-number">#{order.id}</span> - Total: <span className="total">{order.total || order.totalPrice} {order.currency}</span> - Status: <span className="status" style={{fontWeight:'bold',fontSize:'20px'}}>{order.status}</span>
                        </p>
                        <ul>
                        
                        {(Array.isArray(order.cartItems) && order.cartItems.length > 0) || (Array.isArray(order.items) && order.items.length > 0) ? (
     (order.cartItems || order.items).map((item) => (
            <li key={item.id} className="line-item">
                {/* <span style={{ fontWeight: 'bold' }}>{item.title}</span> - Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span> - Price: <span className="item-price">{item.price}</span> */}
                <p>Title: <span style={{ fontWeight: 'bold' }}>{item.title}</span></p>
                                        <p>Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span></p>
                                        <p>Price: <span style={{ fontWeight: 'bold' }}>{item.price}</span></p>
                {/* Check for toppings and render them if available */}
                {Array.isArray(item.toppings) && item.toppings.length > 0 ? (
                    <div className="item-toppings">
                        <h4>Toppings:</h4>
                        <ul>
                            {item.toppings.map((topping, index) => (
                                <li key={index}>{topping}</li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <p>No toppings available.</p>
                )}

                {/* Check for options and render them if available */}
                {item.options && Object.keys(item.options).length > 0 && (
                    <div className="item-options">
                        <h4>Options:</h4>
                        <ul>
                            {Object.entries(item.options).map(([option, value]) => (
                                <li key={option}>
                                    <strong>{option}:</strong> {value ? 'Yes' : 'No'}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </li>
        ))
    ) : (
        <p>No items in this order.</p>
    )}




                            {order.line_items && order.line_items.length > 0 ? (
                                order.line_items.map(item => (
                                    <li key={item.id} className="line-item" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        
                                        {/* <img src={item.image.src} alt={item.name} style={{ maxWidth: '100px', marginBottom: '10px' }} /> */}
                                        <li key={item.id} className="line-item">
                                        <p>Title: <span style={{ fontWeight: 'bold' }}>{item.name}</span></p>
                                        <p>Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span></p>
                                        <p>Price: <span style={{ fontWeight: 'bold' }}>{item.price}</span></p>
</li>
                                        {/* Displaying Topping Options, excluding _ywapo_meta_data */}
                                        {item.meta_data && item.meta_data.length > 0 ? (
                                            <div className="topping-options">
                                                <h4>Topping Options:</h4>
                                                <ul>
                                                    {item.meta_data
                                                        .filter(meta => meta.key !== '_ywapo_meta_data') // Exclude unwanted key
                                                        .map((meta, index) => (
                                                            <li key={index}>
                                                                <strong>{meta.display_key}:</strong> {String(meta.display_value)}
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        ) : (
                                            <p>No topping options available.</p>
                                        )}

                                        {/* Button to show billing information */}
                                        
                                    </li>
                                ))
                            ) : (
                                <p></p>
                            )}
                        </ul>
                        <div className="buttons">
                   
                    
                     {/* <button className="status-button on-hold" onClick={() => {updateOrderStatus2(order.sequenceNumber, 'on-hold') ; updateOrderStatus(order.id, 'on-hold') }}>On Hold</button> */}
                    
                    <button className="status-button completed" onClick={() =>  updateOrderStatus2(order.sequenceNumber, 'completed')}> 
    Delivered
</button>
<button className="status-button completed" onClick={() => {handleShowModal(order.billing || order.billingDetails , order) ; handleShowModal2(order) }}>Show Billing Info</button>
                    
                  </div>
                    </li>
                    
                
                  
                  </>
                ))}
            </ul>
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Billing Information</h2>
                        {selectedBilling ? (
                            <div>
                                <p><strong>First Name:</strong> {selectedBilling.first_name || selectedBilling.firstName}</p>
                                <p><strong>Last Name:</strong> {selectedBilling.last_name || selectedBilling.lastName}</p>
                                <p><strong>Address 1:</strong> {selectedBilling.address_1 || selectedBilling.streetAddress}</p>
                                <p><strong>Address 2:</strong> {selectedBilling.address_2 || selectedBilling.streetAddress}</p>
                                <p><strong>City:</strong> {selectedBilling.city}</p>
                                <p><strong>State:</strong> {selectedBilling.state}</p>
                                <p><strong>Postcode:</strong> {selectedBilling.postcode}</p>
                                <p><strong>Country:</strong> {selectedBilling.country || selectedBilling.state}</p>
                                <p><strong>Phone:</strong> {selectedBilling.phone}</p>
                                <p><strong>Email:</strong> {selectedBilling.email}</p>
                            </div>
                        ) : (
                            <p>No billing information available.</p>
                        )}
                        <button onClick={handleCloseModal}>Close</button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default KitchenOrdersList;
