import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import '../../Styles/Orders.css'; // Assuming you have a CSS file for styles
import { doc, updateDoc } from 'firebase/firestore';
import axios from 'axios';

const firebaseConfig = {
    apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
    authDomain: "hungrie-5717f.firebaseapp.com",
    projectId: "hungrie-5717f",
    storageBucket: "hungrie-5717f.appspot.com",
    messagingSenderId: "121136828215",
    appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
    measurementId: ""
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const KitchenOrdersList = () => {
    const [kitchenOrders, setKitchenOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedBilling, setSelectedBilling] = useState(null);
    const [selectedBilling2, setSelectedBilling2] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchKitchenOrders = async () => {
            try {
                const querySnapshot = await getDocs(collection(firestore, 'CompletedOrders'));
                const orders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                // Sort orders by sequence number
                orders.sort((a, b) => b.sequenceNumber - a.sequenceNumber);
                
                console.log('Fetched Kitchen Orders:', orders);
                setKitchenOrders(orders);
            } catch (err) {
                console.error('Error fetching KitchenOrders:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchKitchenOrders();
    }, []);

    const handleShowModal = (billing, order) => {
        if (billing) {
            setSelectedBilling(billing);
        } else {
            setSelectedBilling2(order); 
        }
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedBilling(null);
    };

    if (loading) {
        return <p>Loading kitchen orders...</p>;
    }

    if (error) {
        return <p>Error loading kitchen orders: {error}</p>;
    }

    const handleShowModal2 = (billing , order) => {
      if (billing) {
          setSelectedBilling(billing);
      } else {
          setSelectedBilling2(order); // Assuming 'order' is accessible here
      }
      setShowModal(true);
  };
    const deliveredOrders = kitchenOrders.filter(order => order.status === 'completed');

    return (
        <div className="orders-container">
            <h1 className="title">Delivered Orders  </h1>
            <ul className="orders-list">
                {deliveredOrders.length > 0 ? (
                    deliveredOrders.map(order => (
                        <li key={order.id} className="order-item">
                            <p style={{ textDecoration: 'underline' }}>
                                Sequence Number: <span style={{ fontWeight: 'bold' }}>{order.sequenceNumber}</span>
                            </p>
                            <p className="order-details">
                                Order <span className="order-number">#{order.id}</span> - Total: <span className="total">{order.total || order.totalPrice} {order.currency}</span> - Status: <span className="status">{order.status}</span>
                            </p>
                            <ul>
                            {(Array.isArray(order.cartItems) && order.cartItems.length > 0) || (Array.isArray(order.items) && order.items.length > 0) ? (
     (order.cartItems || order.items).map((item) => (
                                        <li key={item.id} className="line-item">
                                            <p>Title: <span style={{ fontWeight: 'bold' }}>{item.title}</span></p>
                                            <p>Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span></p>
                                            <p>Price: <span style={{ fontWeight: 'bold' }}>{item.price}</span></p>
                                        </li>
                                    ))
                                ) : (
                                    <p>No items in this order.</p>
                                )}
                                       
                            </ul>
                            <div className="buttons">
                                       <button className="status-button completed" onClick={() => {handleShowModal(order.billing , order) ; handleShowModal2(order) }}>Show Billing Info</button>
                                       </div>
                        </li>
                    ))
                ) : (
                    <p>No delivered orders found.</p>
                )}
            </ul>

            {showModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Billing Information</h2>
                        {selectedBilling ? (
                            <div>
                                <p><strong>First Name:</strong> {selectedBilling.first_name}</p>
                                <p><strong>Last Name:</strong> {selectedBilling.last_name}</p>
                                <p><strong>Address 1:</strong> {selectedBilling.address_1}</p>
                                <p><strong>Address 2:</strong> {selectedBilling.address_2}</p>
                                <p><strong>City:</strong> {selectedBilling.city}</p>
                                <p><strong>State:</strong> {selectedBilling.state}</p>
                                <p><strong>Postcode:</strong> {selectedBilling.postcode}</p>
                                <p><strong>Country:</strong> {selectedBilling.country}</p>
                                <p><strong>Phone:</strong> {selectedBilling.phone}</p>
                                <p><strong>Email:</strong> {selectedBilling.email}</p>
                            </div>
                        ) : (
                            <p>No billing information available.</p>
                        )}
                        <button onClick={handleCloseModal}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default KitchenOrdersList;
